import React from "react"
import {FormElement} from "components/form/FormElement"

export class SvgTextInput extends FormElement {
  getValue = (props) => typeof props.value !== "undefined" ?
      props.value : (typeof props.defaultValue !== "undefined" ?
        props.defaultValue : "")

  getDefaults = (props) => {
    const cursorPosition = this.getValue(props).length;
    return {
      cursorPosition
    };
  }

  inputText = React.createRef()
	area = React.createRef()
  beforeCursor = React.createRef()

  getInputText = () => this.inputText ? this.inputText.current : null

  getBeforeCursor = () => this.beforeCursor ? this.beforeCursor.current : null

  state = {
    ...this.getDefaults(this.props),
    textWidth: 0,
    beforeCursorTextLength: 0,
    cursorOn: true,
    focused: this.props.hasFocus || false,
  }

  toggleCursor = () => this.setState({
    cursorOn: !this.state.cursorOn
  })

  componentDidMount() {
    document.addEventListener("mousedown", this.handleMouseDown);
    document.addEventListener("keydown",this.handleControlKeys);
    document.addEventListener("keypress",this.onKeyUp);
    // this.cursorId=window.setInterval(this.toggleCursor.bind(this),CURSOR_INTERVAL);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleMouseDown);
    document.removeEventListener("keydown", this.handleControlKeys);
    document.removeEventListener("keypress", this.onKeyUp);
    // window.clearInterval(this.cursorId);
  }
  handleMouseDown = (evt) => {
    const area = this.refs.area.current;
    this.setState({
      focused: area && area.contains(evt.target)
    });
  }
  fireChange = (value) => {
    if (typeof this.props.onChange === "function") {
      this.props.onChange(value, this);
    }
  }
  click = (e) => {
    e.stopPropagation();
    if (!this.state.focused) {
      this.setState({
        focused: true
      });
    }
    else {
      this.setState({
        focused:true
      });
    }
    return false;
  }
  handleControlKeys = async (e) => {
    if (!this.state.focused) {
      return;
    }

    const value = this.getValue(this.props);

    const {
      cursorPosition,
    } = this.state;

    let newValueOnBackspace, newValueOnDelete;
    switch(e.keyCode) {
      case 37: //left arrow
        this.setState({
          cursorPosition: Math.max(cursorPosition - 1, 0),
        });
        break;
      case 39: //right arrow
        this.setState({
          cursorPosition: Math.min(cursorPosition + 1, value.length),
        });
        break;
      case 8: //backspace
        e.preventDefault();
        if (cursorPosition === 0) {
          return;
        }

        newValueOnBackspace = value
        .substring(0, cursorPosition - 1)
        .concat(value.substring(cursorPosition));

        await this.fireChange(newValueOnBackspace);

        this.setState({
          cursorPosition: Math.max(cursorPosition - 1, 0),
        });
        break;
      case 46: //delete
        e.preventDefault();
        if (cursorPosition >= value.length) {
          return;
        }

        newValueOnDelete = value
        .substring(0, cursorPosition)
        .concat(value.substring(cursorPosition + 1));

        this.fireChange(newValueOnDelete);
        break;
      case 13: //ENTER
        if (typeof this.props.onEnter === "function") {
          this.props.onEnter(value, this);
        }
        break;
      case 27: //ESCAPE
    }
  }
  onKeyUp = async (e) => {
    const value = this.getValue(this.props);

    const {
      cursorPosition,
      focused
    } = this.state;

    if (!focused) {
      return;
    }

    const key = String.fromCharCode(e.keyCode);
    if (key) {
      const beforeCursor = value.substring(0, cursorPosition);
      const afterCursor = value.substring(cursorPosition);

      const newValue = beforeCursor.concat(key, afterCursor);
      await this.fireChange(newValue);

      this.setState({
        cursorPosition: beforeCursor.length + 1,
      });
    }
  }

  replaceSpaces = (str) => str.replace(/\s/g,"&#160;")

  render() {
    let {
      width = 400,
      height = 50,
      data,
      onEnter,
      ...props
    } = this.props;

    if (props.style.fontSize > 50) {
      height = props.style.fontSize + 20;
    }
    let { cursorPosition, inputWidth, beforeCursorTextLength } = this.state;

    const renderWidth = (
      inputWidth !== 0
      && inputWidth !== null
      && typeof inputWidth !== 'undefined'
    ) ? inputWidth : width;

    const value = this.getValue(props);
    const beforeCursor = value.substring(0, cursorPosition);
    const afterCursor = value.substring(cursorPosition);

    return (
      <svg {...props} ref={area}>
        <rect id={data ? data.id : ''} width={renderWidth} height={height} fill="white" stroke="black" strokeWidth={2} />
        <svg x={10} width={renderWidth}>
          <text
            ref={(c) => {
              if (c) {
                this.inputText = c;
                if (this.state.inputWidth !== (c.getBBox().width + 30)) {
                  this.setState({
                    inputWidth: c.getBBox().width + 30
                  });
                }
              }
            }}
            width={renderWidth}
            y={(height / 2) + (props.style.fontSize / 3)}
            fill={props.style.fill}
            style={{
              fontSize: `${this.props.style.fontSize}px`,
              fontWeight: `${this.props.style.fontWeight}`,
              whiteSpace: 'pre'
            }}
          >
            <tspan ref={(c) => {
              if (c) {
                this.beforeCursor.current = c;
                // we add 10 + 1, 10 for the padding on the wrapping on the svg element
                const beforeCursorTextLength = c.getComputedTextLength() + 11;
                if (this.state.beforeCursorTextLength !== beforeCursorTextLength) {
                  this.setState({
                    beforeCursorTextLength
                  });
                }
              }
            }} dangerouslySetInnerHTML={{__html: this.replaceSpaces(beforeCursor)}} />
            <tspan dangerouslySetInnerHTML={{__html: this.replaceSpaces(afterCursor)}} />
          </text>
        </svg>
        {this.state.cursorOn && this.state.focused && (
          <line
            stroke="black"
            strokeWidth={1}
            x1={beforeCursorTextLength}
            x2={beforeCursorTextLength}
            y1={.1 * height}
            y2={.9 * height}
          />
        )}
      </svg>
    );
  }
}
