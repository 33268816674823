import React from "react";
import BaseActionCreator from "factory/BaseActionCreator";
//import {ConfigContext} from "config";//TODO Rolando
let _keys=Symbol("keys");
let _loaded=Symbol("loaded");
let _loading=Symbol("_loading");
let _packageName=Symbol("content");
let _stores= {};
class I18nStore {
    constructor(packageName) {
        if(!packageName) {throw new Error("package name must be supplied.");}
        this[_packageName]=packageName;
        this[_loaded]=false;
        this[_loading]=false;
        this.getKey=this.getKey.bind(this);
        this.getKeyAsArray=this.getKeyAsArray.bind(this);
    }
    get loaded() {return this[_loaded];}
    get packageName() {return this[_packageName];}

    loadI18nItems() {
        this[_loaded]=true;
        this[_loading]=false;
        this[_keys]=BaseActionCreator.makeWebCallSync(`/I18n(${this[_packageName]})`);
    }

    loadItemsIfNecessary() {
        //TODO Rolando
        /*
        if(ConfigContext.i18nDisabled) {
            return;
        }
        if(isNaN(ConfigContext.getClientContext())) {
            return;
        }

        if(!this.loaded) {
            this.loadI18nItems();
        }        */
    }
    getKeyAsArray(key) {
        //TODO Rolando
        /*
        if(ConfigContext.i18nDisabled) {
            return key;
        }
        if(!ConfigContext.getClientContext()) {
            return null;
        }
        */
        this.loadItemsIfNecessary();
        if(!this.loaded) {
            return "";
        }
        if(!this[_keys][key]) {
            throw new Error("Key not found in package: "+key);
        } else {
            var unique=0;
            var replaceString=(string,index,args) => {
                if(!args.length) {return [string];}
                var arg=args[0];
                var parts=string.split("{"+index+"}");
                var append=[];
                for(var i=0; i<parts.length;i++) {
                    append=append.concat(replaceString(parts[i],index+1,args.slice(1)));
                    if(i<parts.length-1) {
                        append.push(<span key={unique++}>{arg}</span>);
                    }
                }
                return append;
            };
            var str=this[_keys][key];
            return replaceString(str,0,Array.prototype.slice.call(arguments,1));
        }
    }
    getKey(key) {
        //TODO Rolando
        /*
        if(ConfigContext.i18nDisabled) {
            return key;
        }
        if(!ConfigContext.getClientContext()) {
            return null;
        }
        */
        this.loadItemsIfNecessary();
        if(!this.loaded) {
            return "";
        }

        console.log("this", this)
        console.log("key", key)

        //TODO Rolando
        if(this[_keys] && !this[_keys][key]) {
            throw new Error("Key not found in package "+this[_packageName]+": "+key);
        } else {
            //TODO Rolando
            /*
            var str=this[_keys][key];
            for(var i=1;i<arguments.length;i++) {
                var replacement = arguments[i];
                if(replacement && replacement.replace) {
                    replacement=replacement.replace(new RegExp("\\$","g"),"$$$$");
                }
                str=str.replace(new RegExp("\\{"+(i-1)+"\\}","g"),replacement);
            }
            return str;*/
            return "test";
        }

    }
}

export default class I18n {
    static getForPackage(packageName) {
        if(!_stores[packageName]) {
            _stores[packageName]=new I18nStore(packageName);
        }
        return _stores[packageName];
    }
}
