import React from "react";
import { BeizerFunction, LinearFunction } from "util/MathFunctions";
import { EditorSeat } from "components/seatingChart/editor/EditorSeat";
import { SEAT_SIZE, SEAT_SPACING } from "components/seatingChart/shared/SeatingChartConfig";

export class EditorRow extends React.Component {
    constructor(props) {
        super(props);
    }
    static findSeatPosition(sectionSize,width,section,row,seatId) {
        let {skewY,seatAlign,curve,minPosition:sectionOffset} = section;
        let seat=row.seats.filter(seat=>seat.id===seatId)[0];
        if(!seat) {
            return null;
        }
        let skew=new LinearFunction(skewY,skewY<0?-skewY*(sectionSize-1):0);
        let curveGen=new BeizerFunction([0,0],[width,0],sectionSize,[width/2,curve*2]);
        let seatSpacing=new LinearFunction(SEAT_SIZE+SEAT_SPACING,SEAT_SPACING);
        let position=EditorRow.getActualPosition(row,seat.position,seatAlign,sectionSize,sectionOffset);
        let x=seatSpacing.evaluateAt(position);
        let y=curveGen.evaluateAtStep(position)[1]+skew.evaluateAt(position);
        return [x,y];
    }
    static getActualPosition(row,position,seatAlign,sectionSize,sectionOffset) {
        let offset=0;
        let {maxPosition,minPosition} =row;
        //normalize positions according to section min
        // this removes empty spaces at start of row.
        maxPosition-=sectionOffset;
        minPosition-=sectionOffset;
        position-=sectionOffset;
        let positions= (maxPosition-minPosition)+1;
        switch(seatAlign) {
        case "LEFT":
            position=(position-minPosition);
            break;
        case "RIGHT":
            offset=(sectionSize-positions);
            position=(position-minPosition)+offset;
            break;
        case "CENTER":
            offset=(sectionSize-positions)/2;
            position=(position-minPosition)+offset;
            break;
        }
        return position;
    }
    static calculateLabelPosition(data, props) {
        let {width,curve,skewY,sectionSize,sectionOffset,seatAlign="LEFT"}=props;
        let {maxPosition,minPosition} = data;
        maxPosition-=sectionOffset;
        minPosition-=sectionOffset;
        position-=sectionOffset;
        let positions= (maxPosition-minPosition)+1;
        let skew=new LinearFunction(skewY,skewY<0?-skewY*(sectionSize-1):0);
        let curveGen=new BeizerFunction([0,0],[width,0],sectionSize,[width/2,curve*2]);
        let seatSpacing=new LinearFunction(SEAT_SIZE+SEAT_SPACING,SEAT_SPACING);
        let position = minPosition;
        let offset=0;
        switch(seatAlign) {
        case "LEFT":
            position=(position-minPosition);
            break;
        case "RIGHT":
            offset=(sectionSize-positions);
            position=(position-minPosition)+offset;
            break;
        case "CENTER":
            offset=(sectionSize-positions)/2;
            position=(position-minPosition)+offset;
            break;
        }
        return [seatSpacing.evaluateAt(position-1),
            curveGen.evaluateAtStep(position)[1]+skew.evaluateAt(position)+SEAT_SIZE/4
        ];
    }
    shouldComponentUpdate(nextProps) {

      return    !nextProps.cancelRender ||
                !Object.deepEquals(nextProps.data, this.props.data);
    }
    renderSeats() {
        let {width,curve,skewY,data,seatAlign,sectionSize,textRotation=0,onEditSelect,sectionOffset}=this.props;
        let {seats} = this.props.data;
        let skew=new LinearFunction(skewY,skewY<0?-skewY*(sectionSize-1):0);
        let curveGen=new BeizerFunction([0,0],[width,0],sectionSize,[width/2,curve*2]);
        let seatSpacing=new LinearFunction(SEAT_SIZE+SEAT_SPACING,SEAT_SPACING);
        return seats.map((seat)=> {
            let position = EditorRow.getActualPosition(data,seat.position,seatAlign,sectionSize,sectionOffset);
            return <svg x={seatSpacing.evaluateAt(position)} key={seat.id} y={curveGen.evaluateAtStep(position)[1]+skew.evaluateAt(position)}>
                <EditorSeat
                  onEditSelect={onEditSelect}
                  textRotation={textRotation}
                  showSeatNumber={true}
                  isKillSeat={seat.killSeat}
                  isInCart={seat.inCart}
                  selected={this.props.selectedSeats && !!this.props.selectedSeats[seat.id]}
                  {...this.props}
                  data={seat} />
            </svg>;
        });
    }
    render() {
        return <g>
            {this.renderSeats()}
        </g>;
    }
}
