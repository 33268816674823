import React from "react";

/**
Renders a wrapper that renders the key to dom for debugging purposes. Each wrapper must have a resolver,
which contains 2 methods, getKey and getKeyAsArray, which allows the wrapper to resolve the passed keys
**/
export class I18nWrapper extends React.Component{
  constructor(props) {
    super(props);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let newState = this.updateValue(nextProps);
    this.setState(newState);
  }
  UNSAFE_componentWillMount() {
    let updateValue = this.updateValue(this.props);
    this.setState({key : updateValue.key , value: updateValue.value});
  }
  updateValue(props) {
    var args=[props.i18nKey].concat(props.arguments||[]);
    var value=null
    if(props.formatted) {
      value=props.I18nResolver.getKeyAsArray.apply(this,args)
    } else {
      value=props.I18nResolver.getKey.apply(this,args)
    }
    return {
      key:props.i18nKey,
      value: value
    }
  }
  render() {
    let {...props} = this.props;
    if(this.props.asHtml) {
      return <span data-i18n-key={this.state.key} {...props} dangerouslySetInnerHTML={this.state.value}></span>
    }
    return <span data-i18n-key={this.state.key} {...props}>{this.state.value}</span>
  }
}
