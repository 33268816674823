let _cache=Symbol("Cache");
export class Cache {
  constructor(timeout) {
    let options={};
    if(typeof timeout === "object") {
      options=timeout;
      timeout=options.timeout || -1;
    }
    this[_cache]={}
    this.timeout=-1;
    if(typeof timeout ==="number") {
      this.timeout=timeout
    }
  }
  clear() {
      this.purge();
  }
  purge() {
    this[_cache]={};
  }
  delete(key) {
    if(!this[_cache][key]) {
      return;
    }
    delete this[_cache][key]
  }
  get(key) {
    let item=this[_cache][key];
    if(item && this.isExpired(key)) {
      item=null;
      delete this[_cache][key];
    }
    if(!item) {
      return null;
    }
    return item.data;
  }
  isExpired(key) {
    let item=this[_cache][key];
    if(!item) {
      return true;
    }
    if(item.neverExpire) {
      return false;
    }
    return item.timeout>0 && (Date.now()>item.timeout);
  }
  add(key, object, timeout) {
      this.insert(key,object,timeout);
  }
  set(key, object, timeout) {
      this.insert(key,object,timeout);
  }
  insert(key, object, timeout) {
    let itemTimeout=timeout || this.timeout;
    this[_cache][key]={
      data:object,
      neverExpire:itemTimeout<=0,
      timeout:Date.now()+itemTimeout
    }
  }
  insertAll(object) {
    Object.keys(object).forEach(key=>this.insert(key,object[key]));
  }
}
