import React, { createContext, useState } from 'react';

export const LoadErrorMessageContext = createContext({
  errorMessage: null,
  setErrorMessage: () => {},
});

export const LoadErrorMessageProvider = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState(null);

  return (
    <LoadErrorMessageContext.Provider value={{ errorMessage, setErrorMessage }}>
      {children}
    </LoadErrorMessageContext.Provider>
  );
};