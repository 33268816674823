import React from "react";
import Flex from 'components/common/containers/Flex'
import "sass/components/common/messages/_landscapeMessage.scss";
import {Common} from "util/I18nKeys";
import I18nPackages from "stores/I18nStore";
import {I18nWrapper} from "components/I18nWrapper";
let I18nCommon = I18nPackages.getForPackage("Common");

export class LandscapeMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleMessage: this.props.toggleMessage || false
        };
        this.handleToggleMessage = this.handleToggleMessage.bind(this);
    }
    componentDidMount() {
        if (this.props.toggleMessage) {
            this.handleToggleMessage(this.props.isPermanent);
        }
    }
    UNSAFE_componentWillReceiveProps(nProps) {
        if (nProps.toggleMessage) {
            this.handleToggleMessage(nProps.isPermanent);
        }
        if (!nProps.toggleMessage) {
            this.setState({ toggleMessage: false});
        }
    }
    handleToggleMessage(isPermanent) {
        this.setState({ toggleMessage: true }, () => {
            if (!isPermanent) {
                setTimeout(() => {
                    this.setState({ toggleMessage: false});
                }, 2000);
            }
        });
    }

    isValid(toggleMessage){
        if (toggleMessage && window.devicePixelRatio > 2) {
            return true;
        } else {
            return false;
        }
    }
    render() {
        let { toggleMessage } = this.state;
        const { width, height } = this.props;
        return (
            <div className="ot_landscapeMessageScreen" style={{ width, height }}>
                <Flex columns="1" fixed className={`ot_landscapeMessageBox ${this.isValid(toggleMessage) ? "showMessage" : ""}`}>
                    <h3 className="ot_landscapeMessage anime_fadeIn">
                        {this.props.message || <I18nWrapper I18nResolver={I18nCommon} i18nKey={Common.Media.landscapeMessage} />}
                    </h3>
                </Flex>
            </div>
        );
    }
}

LandscapeMessage.propTypes = {
    toggleMessage: PropTypes.bool,
    isPermanent: PropTypes.bool,
    message: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default LandscapeMessage;
