import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'

import { theme, colors } from '../../../../../theme'
import { addDecimals } from '../../../../../util/Utils'

import { TicketContext } from '../ticketContext/TicketContext'
import { Container, TicketEl, TicketName } from './SharedTicketElements'

const TicketPrice = styled.p`
	font-size: ${theme.fontSizes.sm};
	color: ${colors.primary.first};
	font-weight: ${theme.fontWeight.semiBold};
`

const Ticket = ({ ticket, isFirstTicket = true, resetPromo }) => {
	const ticketContext = useContext(TicketContext)
	const {
		setSelectedTicket,
		setselectedTicketTitle,
		selectedTicketTitle,
		setTicketForPricing,
		selectedPromo,
		setSelectedPromo,
	} = ticketContext

	useEffect(() => {
		setDefaults()
	}, [])

	const setDefaults = () => {
		if (!isFirstTicket) return

		setselectedTicketTitle(ticket.name)
		setSelectedTicket(ticket)
		setTicketForPricing(ticket)
	}

	const handleTicketClick = () => {
		setselectedTicketTitle(ticket.name)
		setSelectedTicket(ticket)

		if (selectedPromo?.name !== 'Full price') {
			setSelectedPromo(
				ticket.promotion.find((p) => p?.name === selectedPromo?.name)
			)
			setTicketForPricing(
				ticket.promotion.find((p) => p?.name === selectedPromo?.name)
			)
		} else {
			setSelectedPromo({ ...ticket, name: 'Full price' })
			setTicketForPricing(ticket)
		}

		resetPromo(ticket)
	}

	const isTicketSelected = () => {
		return selectedTicketTitle == ticket.name
	}

	const renderPrice = () => {
		const promo = ticket.promotion.find((p) => p?.name === selectedPromo?.name)
		if (promo) {
			return `$${addDecimals(promo.price)}`
		}

		return selectedPromo?.name !== 'Full price'
			? '-'
			: `$${addDecimals(ticket.price)}`
	}

	return (
		<TicketEl onClick={handleTicketClick} selected={isTicketSelected()}>
			<Container>
				<TicketName>{ticket.name}</TicketName>

				<TicketPrice>{renderPrice()}</TicketPrice>
			</Container>
		</TicketEl>
	)
}

export default Ticket
