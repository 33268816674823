export class VectorUtils {
	/**
	 * @static vectorPerElementOperation - runs a reduce operation for a number of vectors. The reduce function
	 * is supplied and run for each elemement in each vector.
	 *
	 * Example: VectorUtils.vectorPerElementOperation(Math.max,[0,1],[1,-2],[3,0]) -> [3,1]
	 * It runs a max operation over all vectors for each element in the vector
	 *
	 * @param  {Function} func    a function that takes two number arguments and returns a single number. THis is the reduce operation
	 * @param  {...number[]} vecs a list of vectors to reduce. All vectors must be the same length
	 * @return {number[]}         a single vector
	 */
	static vectorPerElementOperation(func, ...vecs) {
		vecs = VectorUtils.preventNull(...vecs)
		if (vecs.length === 1) {
			if (VectorUtils.isVector(vecs[0])) {
				throw new Error('All arguments must be vectors')
			}
			return vecs[0]
		}
		let ret = vecs[0]
		for (let i = 1; i < vecs.length; i++) {
			VectorUtils.assertSameLength(vecs[i], ret)
			ret = vecs[i].map((item, index) => func(item, ret[index]))
		}
		return ret
	}

	/* ensures that a null vector or undefined returns as an empty array */
	static preventNull(...vecs) {
		return vecs.map((item) => item || [])
	}

	static isVector(vec) {
		return Array.isArray(vec)
	}

	static assertSameLength(vec1 = [], vec2 = [], msg) {
		if (!VectorUtils.isVector(vec1) || !VectorUtils.isVector(vec2)) {
			throw new Error('Argument is not vector')
		}
		if (vec1.length != vec2.length) {
			throw new Error(msg || 'Vectors not same length')
		}
	}

	static assertSameVectors(vec1 = [], vec2 = []) {
		if (!VectorUtils.isVector(vec1) || !VectorUtils.isVector(vec2)) {
			throw new Error('Argument is not vector')
		}
		if (vec1.length != vec2.length) {
			throw new Error('Vectors not same length')
		}
		for (let i = 0; i < vec1.length; i++) {
			if (vec1[i] !== vec2[i]) return false
		}
		return true
	}

	//must be a vector cannot be a matrix
	static toVector(...items) {
		if (Array.isArray(items[0])) {
			return items[0]
		}
		return items
	}

	/**
	 * @static max - computes the max of every individual element in a set of vectors
	 *
	 * Example: VectorUtils.max([0,1,2],[1,2,0],[2,0,1])->[2,2,2]
	 *
	 * @param  {...number[]} vecs the vectors to take the max of. all vectors must be same length
	 * @return {number[]} a single vector where each element is the maximum element of its index among all the arguments
	 */
	static max(...vecs) {
		return VectorUtils.vectorPerElementOperation(Math.max, ...vecs)
	}

	/**
	 * @static min - computes the min of every individual element in a set of vectors
	 *
	 * Example: VectorUtils.min([0,1,2],[1,2,0],[2,0,1])->[0,0,0]
	 *
	 * @param  {...number[]} vecs the vectors to take the min of. all vectors must be same length
	 * @return {number[]} a single vector where each element is the minimum element of its index among all the arguments
	 */
	static min(...vecs) {
		return VectorUtils.vectorPerElementOperation(Math.min, ...vecs)
	}

	/**
	 * @static abs - computes the absolute value of every element in a given vector
	 *
	 * Example: VectorUtils.abs([-1,0,1])->[1,0,1]
	 *
	 * @param  {number[]} vec1 a single vector to take the absolute value of
	 * @return {number[]} a single vector where each element is the absolute value of the element at the corresponding index of the argument vector
	 */
	static abs(vec1) {
		if (!vec1 || !vec1.length) {
			throw new Error('Invalid vector')
		}
		return vec1.map((item, index) => Math.abs(item))
	}
	/**
	 * @static magnitude - computes the linear algebra maginitude of a given vector. in other words. it is the euclidean distance
	 * between the origin and a point represented by the vector.
	 *
	 * Example: VectorUtils.magnitude([3,4])->5
	 *
	 * @param  {number[]} vec1 a single vector to take the magnitude of
	 * @return {number} a number representing the size of the array
	 */
	static magnitude(vec1) {
		if (!vec1 || !vec1.length) {
			throw new Error('Invalid vector')
		}
		return Math.sqrt(vec1.reduce((acc, val) => acc + val * val, 0))
	}

	/**
	 * @static add - adds two or more vectors together
	 *
	 * @param  {...number[]} vecs the vectors to add
	 * @return {number[]} the result vector of the add operation
	 */
	static add(...vecs) {
		return VectorUtils.vectorPerElementOperation(
			(item, lastVal) => item + lastVal,
			...vecs
		)
	}

	static average(...vecs) {
		return VectorUtils.scalarMultiply(VectorUtils.add(...vecs), 1 / vecs.length)
	}

	/**
	 * @static midpoint - computes the midpoint between two points
	 *
	 * @param  {number[]} [vec1=[]] the first endpoint
	 * @param  {number[]} [vec2=[]] the second enpoint
	 * @return {number[]} a point (vector) that lies exactly between the two arguments
	 */
	static midpoint(vec1 = [], vec2 = []) {
		return VectorUtils.average(vec1, vec2)
	}

	/**
	 * @static subtract - subtracts two or more vectors together
	 *
	 * @param  {...number[]} vecs the vectors to subtract
	 * @return {number[]} the result vector of the subtract operation
	 */
	static subtract(...vecs) {
		return VectorUtils.vectorPerElementOperation(
			(item1, lastVal) => lastVal - item1,
			...vecs
		)
	}

	/**
	 * @static scalarMultiply - multiplies each element in a vector by a constant operator
	 *
	 * Example: VectorUtils.scalarMultiply([2,4,8],4)->[8,16,32]
	 *
	 * @param  {number[]} [vec1=[]] description
	 * @param  {number} operand a number to multiply each element in the vector by
	 * @return {type}         description
	 */
	static scalarMultiply(vec1 = [], operand) {
		[vec1] = VectorUtils.preventNull(vec1)
		if (!VectorUtils.isVector(vec1)) {
			throw new Error('First argument should be vector')
		}
		if (typeof operand !== 'number') {
			throw new Error('Second argument should be number')
		}
		return vec1.map((item, index) => item * operand)
	}
	/**
	 * @static componentMultiply - multiplies multiple vectors where each all elements of the same index are multiplied together
	 *  This is not a linear algebra operation, it simply batch multiplies numbers by
	 * their correspondeng component in the vector
	 *
	 * Example: VectorUtils.componentMultiply([1,2,3],[2,3,4],[7,2,2])->[14,12,24]
	 *
	 * @param  {...number[]} vecs vectors to multiply
	 * @return {number[]}  vector where elements are multiplied
	 */
	static componentMultiply(...vecs) {
		return VectorUtils.vectorPerElementOperation(
			(item, lastVal) => item * lastVal,
			...vecs
		)
	}

	/**
	 * @static componentDivide - similar to componentMultiply, but divide operation instead
	 *    Example: VectorUtils.componentMultiply([14,12,24],[7,4,6])->[2,3,4]
	 * @param  {number[]} [vec1=[]] vector of numbers to be divided
	 * @param  {number[]} [vec2=[]] vector fo divisors
	 * @return {number[]}    vector where elements are divided
	 */
	static componentDivide(vec1 = [], vec2 = []) {
		[vec1, vec2] = VectorUtils.preventNull(vec1, vec2)
		VectorUtils.assertSameLength(vec1, vec2)

		return vec1.map((item, index) => item / vec2[index])
	}

	/* Linear Algebra vector multiply */
	static multiply(vec1 = [], vec2 = [], operand) {
		[vec1, vec2] = VectorUtils.preventNull(vec1, vec2)
		VectorUtils.assertSameLength(vec1, vec2)
		return vec1
			.map((item, index) => item * vec2[index])
			.reduce((acc, val) => acc + val, 0)
	}

	static bound(vec = [0, 0], min, max) {
		[vec] = VectorUtils.preventNull(vec)
		min = min || Array(vec.length).fill(Number.MIN_VALUE)
		max = max || Array(vec.length).fill(Number.MAX_VALUE)
		return VectorUtils.min(VectorUtils.max(vec, min), max)
	}

	static fromObject(object, ...fields) {
		if (!fields) {
			return Object.keys(object).map((f) => object[f])
		}
		return fields.map((field) => object[field])
	}
}
