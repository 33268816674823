export const EditorEvents = {
    SELECT_OBJECT: "SELECT_OBJECT",
    REMOVE_OBJECT: "REMOVE_OBJECT",
    REMOVE_NEW_OBJECT: "REMOVE_NEW_OBJECT",
    NEW_OBJECT: "NEW_OBJECT",
    RESET_EDITOR: "RESET_EDITOR",
    CHANGE_TOOL: "CHANGE_TOOL",
    UPDATE_SCALE: "UPDATE_SCALE",
    UPDATE_VIEWPORT:"UPDATE_VIEWPORT",
    INIT_VIEW: "INIT_VIEW",
    UPDATE_VIEW: "UPDATE_VIEW",
    RESET_PAN: "RESET_PAN",
    SELECT_RESIZE_POINT: "SELECT_RESIZE_POINT",
    SAVE_CHART: "SAVE_CHART",
    PROCESSING_STARTED: "PROCESSING_STARTED",
    PROCESSING_COMPLETE: "PROCESSING_COMPLETE",
    UPDATE_UNDO_SECTION_HISTORY: "UPDATE_UNDO_SECTION_HISTORY",
    UPDATE_REDO_SECTION_HISTORY: "UPDATE_REDO_SECTION_HISTORY",
};

export const ClientTicketSelectionEvents = {
    SELECT_TEMPORARY_SEAT: "SELECT_TEMPORARY_SEAT",
    ADD_SELECTED_SEATS: "ADD_SELECTED_SEATS",
};

export const ClientSeatingChartEvents = {
    DRAG_SEATING_CHART: "DRAG_SEATING_CHART",
    RESET_DRAG_SEATING_CHART: "RESET_DRAG_SEATING_CHART",
};

export const CartEvents = {
    CART_VALIDATION_SUCCESS: "CART_VALIDATION_SUCCESS",
    CartChange: {
        ITEM_ADDED:"ITEM_ADDED",
        ITEM_REMOVED:"ITEM_REMOVED",
        DELIVERY_METHOD_UPDATE:"DELIVERY_METHOD_UPDATE",
        CONTACT_DETAILS_UPDATE: "CONTACT_DETAILS_UPDATE",
        CONTACT_DETAILS_UPDATING: "CONTACT_DETAILS_UPDATING",
        GUEST_LIST_UPDATE:"GUEST_LIST_UPDATE",
        TICKET_NAME_UPDATED: "TICKET_NAME_UPDATED",
        CART_CLEARED: "CART_CLEARED",
        CART_LOAD: "CART_LOAD",
        APPLY_CREDITS:"APPLY_CREDITS",
        PACKAGE_RENEWAL_UPDATE: "PACKAGE_RENEWAL_UPDATE",
    },
    CartErrors : {
        CART_LOAD_FAILED:"CART_LOAD_FAILED",
        ITEM_ADD_FAILED:"ITEM_ADD_FAILED",
        CART_CLEAR_FAILED: "CART_CLEAR_FAILED",
        ITEM_REMOVE_FAILED:"ITEM_REMOVE_FAILED",
        TICKET_NAME_UPDATE_FAILED:"TICKET_NAME_UPDATE_FAILED",
        PACKAGE_RENEWAL_UPDATE_FAILED: "PACKAGE_RENEWAL_UPDATE_FAILED",
        CART_VALIDATION_FAILURE: "CART_VALIDATION_FAILURE",
        CART_EXPIRED: "CART_EXPIRED",
        CART_ALMOST_EXPIRED: "CART_ALMOST_EXPIRED",
        RESET_CART: 'RESET_CART'
    }
};
export const DeliveryMethodEvents = {
    DELIVERY_METHOD_LOAD: "DELIVERY_METHOD_LOAD",
    DELIVERY_METHOD_LOAD_FAILED:"DELIVERY_METHOD_LOAD_FAILED"
};

export const ContactDetailDataEvents = {
    CONTACT_DETAIL_TITLES_LOAD:"CONTACT_DETAIL_TITLES_LOAD",
    CONTACT_DETAIL_COUNTRIES_LOAD:"CONTACT_DETAIL_COUNTRIES_LOAD",
    CONTACT_DETAIL_COUNTRIES_LOAD_FAILED:"CONTACT_DETAIL_COUNTRIES_LOAD_FAILED",
    CONTACT_DETAIL_STATES_LOAD:"CONTACT_DETAIL_STATES_LOAD",
    CONTACT_DETAIL_STATES_LOAD_FAILED:"CONTACT_DETAIL_STATES_LOAD_FAILED",
    CONTACT_DETAILS_TITLES_LOAD:"CONTACT_DETAILS_TITLES_LOAD",
    CONTACT_DETAILS_TITLES_LOAD_FAILED:"CONTACT_DETAILS_TITLES_LOAD_FAILED",
    CONTACT_DETAILS_UPDATE: "CONTACT_DETAILS_UPDATE",
    CONTACT_DETAILS_UPDATE_FAILED: "CONTACT_DETAILS_UPDATE_FAILED",
    CONTACT_DETAILS_UPDATE_CONFLICT: "CONTACT_DETAILS_UPDATE_CONFLICT",
    CONTACT_DETAILS_EMAIL_PREFERENCES_LOAD: "CONTACT_DETAILS_EMAIL_PREFERENCES_LOAD"
};

export const CheckoutEvents = {
    CART_ALMOST_EXPIRED:"CART_ALMOST_EXPIRED",
    CART_ALMOST_EXPIRED_RESET:"CART_ALMOST_EXPIRED_RESET",
    REFRESH_CART:"REFRESH_CART",
    TIMER_RESET_DONE:"TIMER_RESET_DONE",
    APPLY_CREDITS:"APPLY_CREDITS",
    MASTERPASS_CHECKOUT: "MASTERPASS_CHECKOUT",
    MASTERPASS_RESET: "MASTERPASS_RESET",
    CART_VALIDATION_SUCCESS_MP: "CART_VALIDATION_SUCCESS_MP",
    COMPLETED_ORDER_LOADED: "COMPLETED_ORDER_LOADED",
    CAMPAIGN_UPDATE: "CAMPAIGN_UPDATE",
    CAMPAIGNS_LOADED: "CAMPAIGNS_LOADED",
    CHECKOUT_STEP_CHANGED:"CHECKOUT_STEP_CHANGED",
    SCROLL_TO_ELEMENT: "SCROLL_TO_ELEMENT",
    CHECKOUT_QUESTIONS_LOADED: "CHECKOUT_QUESTIONS_LOADED",
    CHECKOUT_ANSWER_SUBMIT: "CHECKOUT_ANSWER_SUBMIT",
    CHECKOUT_QUESTIONS_ANSWERED: "CHECKOUT_QUESTIONS_ANSWERED",
    PAYMENT_INFO_CHANGED:"PAYMENT_INFO_CHANGED",
    TOS_INFO_CHANGED:"TOS_INFO_CHANGED",
    CHECKOUT_COMPLETED:"CHECKOUT_COMPLETED",
    ORDER_COMPLETED:"ORDER_COMPLETED",
    ORDER_SUBMITTED:"ORDER_SUBMITTED",
    VALIDATION_STATE_CHANGE:"VALIDATION_STATE_CHANGE",
    PROMOTION_CODE_REMOVED: "PROMOTION_CODE_REMOVED",
    PROMOTION_CODE_ADDED: "PROMOTION_CODE_ADDED",
    TM_OPTIN:"TM_OPTIN",
    CLIENT_OPTIN:"CLIENT_OPTIN",
    WOS_OPTIN:"WOS_OPTIN",
    TICKET_PROTECTION_LOADED:"TICKET_PROTECTION_LOADED",
    TICKET_PROTECTION_RELOADED:"TICKET_PROTECTION_RELOADED",
    Errors: {
        PROMOTION_CODE_ADD_FAILURE: "PROMOTION_CODE_ADD_FAILURE",
        PROMOTION_CODE_REMOVE_FAILURE:"PROMOTION_CODE_REMOVE_FAILURE",
        CHECKOUT_FAILURE: "CHECKOUT_FAILURE",
        CART_VALIDATION_FAILURE: "CART_VALIDATION_FAILURE",
        PS_TOKEN_FAILURE:"PS_TOKEN_FAILURE"
    }
};

export const GlobalEvents = {
    CALL_TO_ACTION_NOTIFICATION: "CALL_TO_ACTION_NOTIFICATION",
    WARNING_MESSAGES_LOAD_EVENT : "WARNING_MESSAGES_LOAD_EVENT",
    SHOW_LOGIN:"SHOW_LOGIN"
};
export const OTContextEvents = {
    PATON_CREDITS_LOADED: "PATON_CREDITS_LOADED",
    CLIENT_CONTEXT_LOAD_EVENT: "CLIENT_CONTEXT_LOAD_EVENT",
    MISMATCH_CONSUMER_RECORDS:"MISMATCH_CONSUMER_RECORDS",
    CONSUMER_LOGOUT:"CONSUMER_LOGOUT",
    CONSUMER_LOGIN:"CONSUMER_LOGIN",
    "CONSUMER_REGISTER":"CONSUMER_REGISTER",
    "PASSWORD_RESET":"PASSWORD_RESET"
};

export const CalendarEvents = {
    PERFORMANCE_DETAILS_LOADED:"PERFORMANCE_DETAILS_LOADED",
    PRODUCTION_DETAILS_LOADED:"PRODUCTION_DETAILS_LOADED",
    PRODUCTION_LOAD_FAILED:"PRODUCTION_LOAD_FAILED",
    PERFORMANCE_LOAD_FAILED:"PERFORMANCE_LOAD_FAILED",
    SEATING_CHART_LOADED:"SEATING_CHART_LOADED",
    SEATING_CHART_BY_ID_LOADED:"SEATING_CHART_BY_ID_LOADED",
    SEATING_CHART_SAVE_ERROR:"SEATING_CHART_SAVE_ERROR",
    NO_SEATING_CHART:"NO_SEATING_CHART",
    "PERFORMANCE_CACHE_INVALIDATE":"PERFORMANCE_CACHE_INVALIDATE",
    CALENDAR_PRODUCTIONS_LOADED: "CALENDAR_PRODUCTIONS_LOADED",
    Errors: {
        CALENDAR_PRODUCTIONS_FAILURE: "CALENDAR_PRODUCTIONS_FAILURE"
    }
};

export const StoreEvents = {
    GIFT_CARDS_LOADED:"GIFT_CARDS_LOADED",
    GIFT_CARD_LOADED:"GIFT_CARD_LOADED",
    GIFT_CARD_HELP:"GIFT_CARD_HELP",
    GIFT_CARD_REGISTERED: "GIFT_CARD_REGISTERED",
    PRODUCTS_UPSELL_LOADED: "PRODUCTS_UPSELL_LOADED",
    PACKAGES_LOADED: "PACKAGES_LOADED",
    PACKAGE_LOADED: "PACKAGE_LOADED",
    BUNDLED_PACKAGE_PRICE_SELECTED: "BUNDLED_PACKAGE_PRICE_SELECTED",
    PACKAGE_PROMO_CODE_VALIDATED: "PACKAGE_PROMO_CODE_VALIDATED",
    FIXED_PACKAGE_LOADED: "FIXED_PACKAGE_LOADED",
    FIXED_PACKAGE_LOAD_ERROR: "FIXED_PACKAGE_LOAD_ERROR",
    FIXED_PACKAGE_TICKET_LOADED: "FIXED_PACKAGE_TICKET_LOADED",
    FIXED_PACKAGE_PERFORMANCE_SELECTED: "FIXED_PACKAGE_PERFORMANCE_SELECTED",
    FIXED_PACKAGE_CHART_LOADED: "FIXED_PACKAGE_CHART_LOADED",
    FIXED_PACKAGE_COMMON_CHART_LOADED: "FIXED_PACKAGE_COMMON_CHART_LOADED",
    FIXED_PACKAGE_PERFORMANCE_LOADED: "FIXED_PACKAGE_PERFORMANCE_LOADED",
    FIXED_PACKAGE_AUTO_SEAT_SELECTION_LOADED: "FIXED_PACKAGE_AUTO_SEAT_SELECTION_LOADED",
    Errors: {
        PACKAGE_LOAD_ERROR: "PACKAGE_LOAD_ERROR",
        PROMOTION_CODE_ADD_FAILURE: "PROMOTION_CODE_ADD_FAILURE",
        NO_FIXED_PACKAGE_LOADED: "NO_FIXED_PACKAGE_LOADED",
        FIXED_PACKAGE_PERFORMANCE_SELECT_ERROR: "FIXED_PACKAGE_PERFORMANCE_SELECT_ERROR",
        NO_FIXED_PACKAGE_CHART: "NO_FIXED_PACKAGE_CHART",
        NO_FIXED_PACKAGE_COMMON_CHART: "NO_FIXED_PACKAGE_COMMON_CHART",
        FIXED_PACKAGE_AUTO_SEAT_SELECTION_FAILURE: "FIXED_PACKAGE_AUTO_SEAT_SELECTION_FAILURE"
    }
};

export const ChartDataFixEvents = {
    ALL_SEATING_CHART_LOADED:"ALL_SEATING_CHART_LOADED",
};

export const GeneralStates = {
    NOT_LOADED:"NOT_LOADED",
    LOADING: "LOADING",
    DONE: "DONE",
    RELOAD:"RELOAD"
};
