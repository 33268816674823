import React from "react";
import {Callout} from 'components/common/containers/Callout';
import 'sass/components/seatingChart/seatSelection/accessibilitySeatFilterMenu.scss';
import {PrimaryButton} from 'components/form/buttons/Button';

export class AccessibilitySeatFilterMenu extends React.PureComponent {
    state={
        priceLevelFilters: [],
        checked: false
    }
    
    handleClick = (e) => {
        e.stopPropagation();
    };
    
    handleApply = ()=> {
        let priceLevelsLocal = this.props.priceLevels;
        let priceLevelsApply = [];
        const dropContainer = this.props.dropContainer.current;
        if(priceLevelsLocal && priceLevelsLocal.length > 0){
            for(let priceLevel of priceLevelsLocal) {
                if(this.isPriceLevelIsHandicappedOrCampanion(priceLevel)) {
                    priceLevelsApply.push(priceLevel.priceLevel.id);
                }
            }
        }
        this.setState({priceLevelFilters : priceLevelsApply,
                        checked: true}
                        , () => {this.props.onFilterChange(this.state.priceLevelFilters, this.state.checked);});
        dropContainer.handleClickOut();
}

    isPriceLevelIsHandicappedOrCampanion = (priceLevel) => {
        return priceLevel.priceLevel && (priceLevel.priceLevel.type === 'HANDICAP' || priceLevel.priceLevel.type === 'WHEELCHAIRCOMPANION' || priceLevel.priceLevel.type === 'WHEELCHAIR_COMPANION');
    }

      //Handle Reset button
    handleReset = ()=> {
        let priceLevelsOriginal = this.props.priceLevels;
        let priceLevelsReset = [];
        const dropContainer = this.props.dropContainer.current;
        if(priceLevelsOriginal && priceLevelsOriginal.length > 0){
            for(let priceLevel of priceLevelsOriginal) {
                if(priceLevel.priceLevel && priceLevel.priceLevel.ticketTypes && priceLevel.priceLevel.ticketTypes.length > 0) {
                    priceLevelsReset.push(priceLevel.priceLevel.id);    
                }
            }
        }
        this.setState({checked: false}
            , () => this.props.onFilterChange(priceLevelsReset, false));
        dropContainer.handleClickOut();
    }

    getBGColourOfButton = () => {
        let primaryButton = document.getElementById("colourGetter")
        return window.getComputedStyle(primaryButton).backgroundColor;
    }

    render() {
        return(
        <Callout className="ot_accessibilityFilterMenu" onClick={this.handleClick}>
            <div className="accessibilityFilterMenu_header">
                Accessible Seating
                <div className="accessibilityFilter_checkBox">
                    <label className="switch">
                        <input type="checkbox" checked={ this.state.checked } disabled />
                        <span className="slider round" style={{backgroundColor: this.state.checked ? this.getBGColourOfButton() : "#B2ABBA" }}></span>
                    </label>
                </div>
            </div>
            <div className="accessibilityFilter_description">
                Accessible seating is intended for patrons with accessibility needs only. By purchasing a ticket for an accessible seat, you acknowledge that you or someone in your party requires accessible features offered by these seats.
            </div>
            <div className="accessibilityFilter_footer">
                <PrimaryButton id="colourGetter" className="resetButton" onClick={this.handleReset}>
                Reset
                </PrimaryButton>
                <PrimaryButton className="applyButton" onClick={this.handleApply}>
                Apply
                </PrimaryButton>
            </div>
        </Callout>);
    }
}