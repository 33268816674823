export const OtColors = {
	black: '#222',
	white: '#fff',
	off_white: '#fcfcfc',
	gray_light: '#eee',
	gray_rule:  '#ddd',
	gray_mid_light: '#D6DDE7',
	gray: '#888',
	gray_mid_dark:  '#666',
	gray_dark:  '#3b454a',
	admin_blue: '#00B1F9',
	admin_red:  '#fa598e', //'#990000',
	ot_blue: '#008cff',
	dark_blue:  '#0670C7',
	light_blue: '#e0f2ff',
	red:  '#990000',
	green:  '#76d164',
	yellow: '#fdbc34',
};

export const OtRGBColors = {
	black: '34, 34, 34',
	white: '255,255,255',
	off_white: '252,252,252',
	gray_light: '238,238,238',
	gray_rule:  '221,221,221',
	gray_mid_light: '214,221,231',
	gray: '136,136,136',
	gray_mid_dark:  '102,102,102',
	gray_dark:  '34,34,34',
	ot_blue: '0,140,255',
	admin_blue: '0,177,249',
	light_blue: '224,242,255',
	dark_blue:  '6, 112, 199',
	red:  '153,0,0',
	green:  '49,178,28',
	yellow: '245,166,35',
};
