import moment from "moment";
import accounting from "accounting";

export class HTMLFormatter {
  static removeHTML(html) {
    var node = document.createElement('span');
    node.innerHTML = html;
    return node.innerText;
  }
}

export class NumberFormatter {
  static formatCurrency(amount, symbol, places,decimalSeparator,groupingSeparator) {
    if(arguments.length<3) {
      places=2;
    }
    if(arguments.length<2) {
      symbol=NumberFormatter.USD;
    }
    decimalSeparator=decimalSeparator||".";
    return accounting.formatMoney(amount,symbol,places,groupingSeparator, decimalSeparator)
  }
}
NumberFormatter.USD = "$";
NumberFormatter.GBP ="£"

export class DateFormatter {
  static format(date, format) {
    if(!date) {
        throw new Error("Date to format must be supplied.")
    }
    if(!format || format===DateFormatter.JSON) {
      return moment(date).toDate().toJSON();
    }
    return moment(date).format(format);
  }
  static formatDate(date,format) {
    if(!format) {
      format=DateFormatter.SHORT_DATE;
    }
    return this.format(date,format);
  }
  static formatFullDate(date) {
    return this.format(date, DateFormatter.MEDIUM_DATE);
  }
  static formatTime(date,format) {
    if(!format) {
      format=DateFormatter.TIME_12;
    }
    return this.format(date,format)
  }
  static isValid(date,format) {
    return moment(date,format).isValid();
  }
  static parse(date,format) {
    return moment(date,format).toDate();
  }
  static addFromNow(amount,interval) {
    return DateFormatter.addFrom(moment(),amount,interval);
  }
  static addFrom(date,amount,interval) {
    return moment(date).add(amount,interval);
  }
}
/*Built in date formates (Common)*/
DateFormatter.MONTH_YEAR='MMMM YYYY';
DateFormatter.LONG_DATETIME='dddd MMMM Do YYYY, h:mm a';
DateFormatter.SHORT_DATETIME='MM/DD/YYYY h:mm a';
DateFormatter.SHORT_DATE='MM/DD/YYYY';
DateFormatter.MEDIUM_DATE='MMMM Do YYYY';
DateFormatter.MEDIUM_LONG_DATE='ddd MMM D, YYYY';
DateFormatter.LONG_DATE='dddd MMMM Do YYYY';
DateFormatter.MOBILE_DATE='ddd MMM D YYYY'
DateFormatter.MOBILE_DATETIME='ddd MMM D YYYY h:mm a'
DateFormatter.PRODUCTION_DISPLAY='ddd, MMM DD';
DateFormatter.FULL_DISPLAY='dddd, MMMM DD'
DateFormatter.TIME_12='h:mm a';
DateFormatter.TIME_24='HH:mm';
DateFormatter.JSON='json string';
