import React from "react";
import PropTypes from 'prop-types';
import { FormElement } from 'components/form/FormElement';
import 'sass/components/form/buttons/_buttons.scss';

export class Button extends FormElement {
	static defaultClassName='ot_button';
	constructor(props,type) {
		super(props);
		type = (typeof type === 'string' ? type : null);
		this.state = {
			buttonType: props.buttonType || type  || ''
		};
		this.button = React.createRef();
		this.click = this.click.bind(this);
		this.componentDidMount = this.__componentDidMount;
		this.componentWillUnmount = this.__componentWillUnmount;
		this.UNSAFE_componentWillReceiveProps = this.__UNSAFE_componentWillReceiveProps;
		this.blur = this.blur.bind(this);
	}
	__componentDidMount() {
		super._componentDidMount.apply(this,arguments);
		if(this.context.parentGroup) {
			this.btnUniqueId = this.context.parentGroup.addElement(this);
		}
	}

	__UNSAFE_componentWillReceiveProps(nProps) {
		if (nProps.active !== undefined && (nProps.active !== this.state.active)) {
			this.toggleActive(nProps.active)
		}
	}

	__componentWillUnmount() {
		super._componentWillUnmount.apply(this,arguments)
		if(this.context.parentGroup) {
			this.context.parentGroup.removeElement(this.btnUniqueId);
		}
	}

	blur() {
		let btn = this.button.current;
		btn.blur();
	}

	toggleActive(active) {
		this.setState({active})
	}

	get value() {
		return this.props.value;
	}

	click(e) {
		var proceed = true;
		if (this.context.parentGroup) {
			this.context.parentGroup.selectButton(e, this, this.btnUniqueId);
		}
		if (this.props.onClick) {
			proceed = this.props.onClick(e)
		}
		let submit = this.props.submit || this.props.type === "submit";
		if (proceed !== false && this.rootForm && submit) {
			this.rootForm.submit();
		}
	}

	render() {
		let { className, children, label, inverse, size = "", ...props } = this.props;
		let { buttonType } = this.state;
		let active = this.state.active ? 'active' : '';
		let disabledClass = props.disabled ? 'disabled' : '';
		let sizeClass;
		if (size) {
			switch (size.toLowerCase()) {
				case 'large':   sizeClass = 'ot_btn-large'; break;
				case 'lg':      sizeClass = 'ot_btn-large'; break;
				case 'medium':  sizeClass = 'ot_btn-medium'; break;
				case 'md':      sizeClass = 'ot_btn-medium'; break;
				case 'small':   sizeClass = 'ot_btn-small'; break;
				case 'sm':      sizeClass = 'ot_btn-small'; break;
				default:        sizeClass = 'ot_btn-fit';
			}
		}
		return (
			<button ref={ this.button }
							role="button"
							onClick={this.click}
							type="button"
							className={`btn ${buttonType ? buttonType : Button.defaultClassName}
							${sizeClass ? sizeClass : ''} ${active ? active : ''} ${disabledClass ? disabledClass : ''}
							${className || ""} ${inverse ? 'inverse' : ''}`}
							{...props} >
					{ label ? label : children }
			</button> );
	}
}

Button.contextTypes=Object.assign({},FormElement.contextTypes,{
	rootForm:PropTypes.object,
	parentForm:PropTypes.object,
	parentGroup:PropTypes.object
});

// Base Buttons
export class DefaultButton extends Button { // Black on selected/hover
	static defaultClassName='ot_defaultButton';
	constructor(props) {
		super(props,DefaultButton.defaultClassName);
	}
}

export class AdminButton extends Button {
	static defaultClassName='ot_adminButton';
	constructor(props) {
		super(props, AdminButton.defaultClassName);
	}
}

export class CancelButton extends Button {
	static defaultClassName='ot_cancelButton';
	constructor(props) {
		super(props,CancelButton.defaultClassName);
	}
}

export class DeleteButton extends Button {
	static defaultClassName='ot_deleteButton';
	constructor(props) {
		super(props,DeleteButton.defaultClassName);
	}
}

export class SubmitButton extends Button {
		static defaultClassName='ot_submitButton';
	constructor(props) {
		super(props,SubmitButton.defaultClassName);
	}
}

export class TransparentButton extends Button {
	static defaultClassName='ot_transparentButton';
	constructor(props) {
		super(props,TransparentButton.defaultClassName);
	}
}

// Custom Buttons
export class PrimaryButton extends Button {
		static defaultClassName='ot_primaryButton';
	constructor(props) {
		super(props,PrimaryButton.defaultClassName);
	}
}

export class SecondaryButton extends Button {
		static defaultClassName='ot_secondaryButton';
	constructor(props) {
		super(props,SecondaryButton.defaultClassName);
	}
}

export class OptionButton extends Button {
		static defaultClassName='ot_optionButton';
	constructor(props) {
		super(props,OptionButton.defaultClassName);
	}
}

export class LinkButton extends Button {
	static defaultClassName='ot_linkButton';
	constructor(props) {
		super(props, LinkButton.defaultClassName);
	}
}

export class CustomButton extends Button {
	static defaultClassName ='ot_customButton';
	constructor(props) {
		super(props, CustomButton.defaultClassName);
	}

	componentDidMount() { // console.log('didMount', this.button);
		if (this.button) {
			let button = this.button.current;

			if (button) {
				// styles button
				CustomButton.handleMouseOut(button, this.props.color, this.props.active);
				// adds event listeners
				button.addEventListener( 'mouseover', () => {
					CustomButton.handleMouseOver( button, this.props.color );
				} );

				button.addEventListener( 'mouseout', () => {
					CustomButton.handleMouseOut( button, this.props.color, this.props.active );
				} );


			}
		}
	}

	UNSAFE_componentWillReceiveProps(nProps) {
		let { active } = nProps;

		// console.log('nProps', active, this.props.active);
		if ( !active || active !== this.props.active ) {
			let button = this.button.current;
			// adds button style...
			if (active) CustomButton.handleMouseOver( button, nProps.color, active );
			else CustomButton.handleMouseOut( button, nProps.color, active);

		}
	}
	componentWillUnmount() {
		let button = this.button.current;

		if (button) {
			button.removeEventListener('mouseover', CustomButton.handleMouseOver);
			button.removeEventListener('mouseout', CustomButton.handleMouseOut);
		}
	}

	static handleMouseOver(button, color) { // console.log('MOUSE OVER', button.value, color);
		if ( button && color) {
			button.style.color = `#fff`;
			button.style.backgroundColor = color;
			button.style.borderColor = color;
		}
	}

	static handleMouseOut(button, color, active) { // console.log('MOUSE OUT', button.value, color);
		if ( button && color && !active) {
				button.style.color = color;
				button.style.backgroundColor = '#fff';
				button.style.borderColor = color;
		}
	}

}

export class NavbarButton extends Button {
	static defaultClassName='ot_navbarButton';
	constructor(props) {
		super(props,NavbarButton.defaultClassName);
	}
}

export class SectionButton extends Button {
	static defaultClassName='ot_sectionButton';
	constructor(props) {
		super(props,SectionButton.defaultClassName);
	}
}






