import React from 'react';
//import "sass/components/seatingChart/seatSelection/ticketWidget/_ticketSelectionWidget.scss";


export class ContextualMap extends React.PureComponent {
    state = {
        contextualMapHeight : 148,
        contextualMapWidth : 250,
        focusHeight : 148,
        focusWidth : 250,
        dragDiff: [0,0],
    };
  contextualMapRef = React.createRef();

  componentDidMount() {
    if ( !!this.props.scale && !!this.props.scale.home && this.props.viewPort && this.props.viewPortChartCenter) this.initializeContextualMiniMap();
  }

  initializeContextualMiniMap = () => { 
      let contextualMiniMap;
      const bigViewportGroupReference = document.getElementById("viewportGroup");
      const miniMap = document.getElementById("miniMap");
      if(miniMap && miniMap.querySelector("#miniMapNewId") == null){
          if(bigViewportGroupReference !== null ){
              contextualMiniMap = bigViewportGroupReference.cloneNode(true);
              let viewPortHeight = this.props.viewPort.viewPortSize[1];
              let viewportWidth = this.props.viewPort.viewPortSize[0];
              let scaleWidth = this.state.contextualMapWidth / viewportWidth;
              let scaleHeight = this.state.contextualMapHeight / viewPortHeight;
              let topOffset = 50 * scaleHeight;
              let newPositionX = this.state.contextualMapWidth / 2 ;
              let newPositionY = this.state.contextualMapHeight / 2;
              miniMap.appendChild(contextualMiniMap);
              contextualMiniMap.setAttribute("id", "miniMapNewId");
              contextualMiniMap.setAttribute("transform", `translate(${newPositionX}, ${newPositionY - topOffset}) scale(${scaleWidth}, ${scaleHeight} )`);
              contextualMiniMap.setAttribute("style", `transform: translate(${newPositionX}px, ${newPositionY - topOffset}px) scale(${scaleWidth}, ${scaleHeight} ); transition: all 0.3s;`);
          }
      }
      this.setState({contextualMiniMap});
  };

  static getDerivedStateFromProps(nextProps, prevState) { 
      let prevViewportScale = prevState.scale ? prevState.scale.current : 0;
      let nextViewportScale = nextProps.scale ? nextProps.scale.current : 0; 
      let scaleStep = nextViewportScale - prevViewportScale; /* calculate scale difference for viewport */
      let focusHeight = prevState.focusHeight, focusWidth = prevState.focusWidth;

      if(nextProps.scale && nextProps.scale.current !== nextProps.scale.home) {  /* if the scale is home reset focus width and height */
        focusWidth = (scaleStep > 0) ? focusWidth * Math.abs(1- Math.abs(scaleStep)) : (scaleStep < 0)? focusWidth / Math.abs(1- Math.abs(scaleStep)) : focusWidth;
        focusHeight =  (scaleStep > 0) ? focusHeight *  Math.abs(1- Math.abs(scaleStep)) : (scaleStep < 0)? focusHeight /Math.abs(1- Math.abs(scaleStep)) : focusHeight;
      } else {
        focusWidth = 250;
        focusHeight = 148;
      }
      let dragDiff = nextProps.pan ? [nextProps.pan.dX, nextProps.pan.dY] : [0,0];

      return {
        scale : nextProps.scale,
        scaleStep : scaleStep,
        focusHeight : focusHeight,
        focusWidth : focusWidth,
        dragDiff : dragDiff,
      }
  }

  calculateContextualMapPan = () => {
    let {scale, viewPort} = this.props;
    let {contextualMapHeight, contextualMapWidth, dragDiff} = this.state;
    let scaleViewport = scale ? scale.current : 1;  
    let scaledPanX = dragDiff ? ( dragDiff[0] * scaleViewport) : 0;
    let scaledPanY = dragDiff ? ( dragDiff[1] * scaleViewport ) : 0;

    let scaleContextualMapWidth = viewPort && viewPort.viewPortSize ? contextualMapWidth / viewPort.viewPortSize[0] : 0 ;
    let scaleContextualMapHeight = viewPort && viewPort.viewPortSize ? contextualMapHeight / viewPort.viewPortSize[1] : 0 ;
    let contextualPanX = scaledPanX * scaleContextualMapWidth ;
    let contextualPanY = scaledPanY * scaleContextualMapHeight ;
    return [contextualPanX , contextualPanY];
  }

	render() {
      let {
        focusWidth,
        focusHeight,
        contextualMapHeight,
        contextualMapWidth,
      } = this.state;

      let {
        scale,
      } = this.props;

      let showMiniMap = ( scale && scale.current != scale.home) ? true : false ;

      // calculate relative pan on contextual map
      let contextualPan = this.calculateContextualMapPan();
      

    return (
      <div className='ot_contextualMap' ref={this.contextualMapRef}>
        <svg id="miniMapViewport" max-width="100%" max-height="100%" viewBox={`0 0 ${contextualMapWidth} ${contextualMapHeight}`} xmlns="http://www.w3.org/2000/svg">
            <svg id="miniMap" max-width="100%" max-height="100%" viewBox={`0 0 ${contextualMapWidth} ${contextualMapHeight}`} xmlns="http://www.w3.org/2000/svg"></svg>
        </svg>
        { showMiniMap &&
            <div className="focusSquare" style={{width:  focusWidth, height: focusHeight , top: `calc(50% - ${contextualPan[1]}px)`, left: `calc(50% - ${contextualPan[0]}px)`}}></div>
        }
      </div>
    );
  }
}