import React from "react";
import {
  EDIT_NUB_SIZE,
  UnitConversion
} from "../SeatingChartConfig";
import {EditableObject} from "./EditableObject";

export class LineObject extends EditableObject 
{
  static getAbsoluteBoundingRect(object) {
    const {
      posX: x,
      posY: y,
      dX: width,
      dY: height
    } = object;

    return UnitConversion.convertRectangleToPixels({
      x,
      y,
      width,
      height,
      center: [
        x + width / 2,
        y + height / 2
      ]
    });
  }

  moveControlPoint(state, editMode) {
    const {
      data,
      data: {
        posX, posY, dX, dY
      },
      editing,
      onPropertyChange
    } = this.props;

    const moveX = UnitConversion.xPixelsToUnits(state.dX);
    const moveY = UnitConversion.yPixelsToUnits(state.dY);

    if (editing && typeof onPropertyChange === "function") {
      let newData = null;
      switch (editMode) {
        case "START":
          newData = {
            posX: posX + moveX,
            posY: posY + moveY,
            dX: dX - moveX,
            dY: dY - moveY
          };
          break;
        case "END":
          newData = {
            dX: dX + moveX,
            dY: dY + moveY
          };
          break;
      }
      if (!newData) {
        return false;
      }
      onPropertyChange(data, newData, this);
    }
    return true;
  }

  convertUnits() {
    const {
      data:{
        posX = 0,
        posY = 0,
        dX = 0,
        dY = 0
      }
    } = this.props;

    return {
      posX: UnitConversion.xUnitsToPixels(posX),
      posY: UnitConversion.yUnitsToPixels(posY),
      dX: UnitConversion.xUnitsToPixels(dX),
      dY: UnitConversion.yUnitsToPixels(dY)
    };
  }

  renderEditingNubs() {
    const {
      posX,
      posY,
      dX,
      dY
    } = this.convertUnits();

    return (
      <g className="editIcons">
        <circle
          onMouseDown={(e) => this.editObject("START")}
          cx={posX}
          cy={posY}
          r={EDIT_NUB_SIZE / 2}
        />
        <circle
          onMouseDown={(e) => this.editObject("END")}
          cx={posX + dX}
          cy={posY + dY}
          r={EDIT_NUB_SIZE / 2}
        />
      </g>
    );
  }

  render() {
    const {
      data, isContextual
    } = this.props;

    const {
      posX,
      posY,
      dX,
      dY
    } = this.convertUnits();

    return (
      <g transform={`rotate(${data.rotation}, ${posX + dX / 2}, ${posY + dY / 2})`}>
        <line
          style={{
            opacity: data.opacity / 100
          }}
          x1={posX}
          x2={posX + dX}
          y1={posY}
          y2={posY + dY}
          fill={isContextual ? "#CCC" : data.fill}
          stroke={isContextual ? "#CCC" : data.stroke}
        />
        {/*this.props.editing && this.renderEditingNubs()*/}
      </g>
    );
  }
}
