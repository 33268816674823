export const EditorConstants = {
	EditorOperationConstants: {
		OBJECT_UNIQUE_ID: -1,
		SAVE_URL: ( id ) => `/SeatingChart(${id}).ot`
	},

    HISTORY_TOOLS: {
      undo: 'UNDO',
      redo: 'REDO',
    },

    RESET_TOOLS: {
      layout: "SELECT",
      create: null,
      action: null,
      property: null,
      outline: null,
      restrictOutline: true
    },
    
	LAYOUT_TOOLS: {
		1: "SELECT",
		2: "PAN"
	},

	CREATE_TOOLS: {
		1: "OBJECT_SHAPE_RECT",
		2: "OBJECT_SHAPE_CIRCLE",
		3: "OBJECT_LABEL",
		4: "OBJECT_SHAPE_CURVE_POLYGON",
		5: "OBJECT_SHAPE_CURVE"
	},

	ACTION_TOOLS: {
		1: "EDIT",
		2: "MOVE",
		3: "PASTE",
		4: "DELETE"
	},

	PROPERTY_TOOLS: {
		1: "SEATS",
		2: "OUTLINE",
	},

	OUTLINE_TOOLS: {
		1: "LINE",
		2: "CURVE",
		3: "ADD",
		4: "DELETE",
		5: "RESET"
	},

	OBJECT_PROPERTIES: {
		FILL: "FILL",
		STROKE: "STROKE",
		STROKE_WIDTH: "STROKE_WIDTH",
		FONT_SIZE: "FONT_SIZE",
		FONT_WEIGHT: "FONT_WEIGHT",
		ROTATION: "ROTATION",
		OPACITY: "OPACITY",
		RADIUS: "RADIUS"
	},

	ACCESSORS: {
		FILL: "fill",
		STROKE: "stroke",
		STROKE_WIDTH: "strokeWidth",
		FONT_SIZE: "fontSize",
		FONT_WEIGHT: "fontWeight",
		ROTATION: "rotation",
		OPACITY: "opacity",
		RADIUS: "radius",
		LAYER: "layer"
	},

	SECTION_ACCESSORS: {
		SEAT_ALIGN: "seatAlign",
		SKEW_X: "skewX",
		SKEW_Y: "skewY",
		CURVE: "curve",
		ROTATION: "rotation",
		ROW_SPACING: "rowSpacing",
	},

	SLIDER_RANGE: {
		fontSize: { max: 72, min: 10 },
		fontWeight: { max: 700, min: 300, i: 100 },
		rotation: { max: 180, min: -180 },
		opacity: { max: 100, min: 0 },
		strokeWidth: { max: 50, min: 1 },
		skewX: { max: 50, min: -50 },
		skewY: { max: 50, min: -50 },
		curve: {max:100, min: -100 },
		rowSpacing: { max: 180, min: -180 },
	},

	SEAT_ALIGNMENT: [ 'NONE', 'LEFT', 'CENTER', 'RIGHT' ],

	SECTION_TABS: {
		SEATS: 'Seats',
		OUTLINE: 'Outline'
	},
};