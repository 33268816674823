import React from "react"
import { FormElement } from "components/form/FormElement";
import { ErrorLabel } from "components/form/elements/ErrorLabel";
import { ArrowButton,ApplyCodeButton } from "components/form/buttons/IconButtons";
import "sass/components/form/form.scss";

export class TextInput extends FormElement {
    static defaultClass = ''

    UNSAFE_componentWillMount() {
        let value = "";
        if (typeof this.props.value !== 'undefined'
            && this.props.value !== null) {
            value = this.props.value;
        }
        else if (typeof this.props.defaultValue !== 'undefined'
            && this.props.defaultValue !== null) {
            value = this.props.defaultValue;
        }

        this.setState({ value });
    }

    get value() {
        return this.refs["input"].value
    }

    get type() {
        return "text"
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (typeof nextProps.value !== 'undefined' &&
            this.props.value !== null && nextProps.value !== this.state.value) {
            this.setState({
                value: nextProps.value
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.validate();
        }
    }

    onChange = (e) => {
        const event = Object.assign({}, e);
        const value = e.target.value;
        this.setState({ value }, () => super.onChange(event, value));
    }

    handleOnBlur = (e) => {
        const event = Object.assign({}, e);
        const value = e.target.value.trim();
        this.setState({ value }, () => super.onBlur(event, value));
    }

    render() {
        let { className, id, name, style, ...props } = this.props;
        let { hideErrorLabel, isValid, showError } = this.state;

        let validClass;
        if (this.props.isCodeCorrect !== undefined) {
            validClass = this.props.isCodeCorrect ? 'success' : 'invalid';
            if (this.props.isEmpty !== undefined) {
                validClass = this.props.isEmpty ? "" : validClass;
            }
            if (this.props.isValueValid !== undefined) {
                validClass = this.props.isValueValid && !this.props.isCodeCorrect ? "" : validClass;
            }
        } else {
            validClass = isValid ? 'success' : 'invalid';
        }
        let showErrorLabel = !hideErrorLabel && showError && !isValid;
        let errorText;
        if (showErrorLabel ) errorText= this.getValidationMessage();
        let dataTest = this.props[`data-test`];
        if (!dataTest) dataTest = name ? this.fullName : id || 'input_field';
        let ariaLabel = this.props[`aria-label`];
        if (!ariaLabel) ariaLabel = `${this.type}_field_for_${ name ? this.fullName : id || '' }`;


        return (
            <div
                className={`ot_inputContainer ${TextInput.defaultClass} ${className} ${className || ''}`}
                style={style}
            >
                <input
                    type={this.type}
                    onBlur={this.props.onBlur ? this.props.onBlur : this.handleOnBlur}
                    onChange={this.onChange}
                    onKeyPress={this.props.onKeyPress ? this.props.onKeyPress : this.props.onBlur}
                    value={this.state.value !== 'undefined' ? this.state.value : ''}
                    className={`ot_textBox ${showError ? validClass : ''}`}
                    name={ name ? this.fullName : null }
                    data-test={ dataTest }
                    aria-label={ ariaLabel }
                    {...props}
                />
                <div className={`ot_error__container ${showErrorLabel ? 'open' : 'close'}`}
                     aria-label={showErrorLabel ? `Error Message: ${errorText}` : '' }
                >
                    <ErrorLabel
                        className="ot_error__label"
                        show={ showErrorLabel }
                        text={errorText}
                        data-test="error-message"
                    />
                </div>
            </div>
        );
    }
}

export class PhoneInput extends TextInput{
    constructor(props) {
        super(props);
    }
    get type() {
        return "tel"
    }
}

export class PasswordInput extends TextInput{
    constructor(props) {
        super(props);
    }
    get type() {
        return "password"
    }
}

export class NumberInput extends TextInput{
    constructor(props) {
        super(props);
    }
    get type() {
        return "number"
    }
}

export class TextArea extends TextInput{
    static defaultClass='';
    constructor(props) {
        super(props);
    }
    render() {
        let { className, name, style, ...props}=this.props
        let { hideErrorLabel, isValid, showError} = this.state;

        let validClass;
        if (this.props.isCodeCorrect !== undefined) {
            validClass = this.props.isCodeCorrect ? 'success' : 'invalid';
        } else {
            validClass = isValid ? 'success' : 'invalid';
        }
        let showErrorLabel = !hideErrorLabel && showError && !isValid;
        return (
            <div className={`ot_inputContainer ${className || ''}`} style={style}>
        <textarea
            aria-label={name}
            data-test={name}
            className={`ot_textArea ${showError ? validClass : ''}`}
            name={name?this.fullName:null}
            onBlur={this.onBlur.bind(this)}
            onChange={this.onChange.bind(this)}
            value={this.state.value}
            {...props} />
                <div className={`ot_error__container ${showErrorLabel ? 'open' : 'close'}`} >
                    <ErrorLabel
                        show={showErrorLabel}
                        text={this.getValidationMessage()} />
                </div>
            </div> );
    }
}

export const InputButton = (props) => (
    <div className={`ot_inputButton ${props.className ? props.className : ''}`}>
        <TextInput aria-label={props[`data-test-input`]}
                   data-test={props[`data-test-input`]}
                   className="input_filed"
                   onFocus={props.onFocus}
                   onKeyUp={props.onChange}
                   onChange={props.onChange}
                   placeholder={props.placeholder}
                   value={props.value}
                   disabled={props.disabled}/>
        <ArrowButton aria-label={props[`data-test-button`]}
                     buttonType="primary"
                     data-test={props[`data-test-button`]}
                     className="submit_button"
                     direction="right"
                     onClick={props.onClick} />
        <p aria-label={`Error ${props.error}`}
           className="ot_ci_error"
           data-test="error">
            { props.error }
        </p>
    </div>
);

export const InputCodeButton = (props) => {
    const { value } = props;
    const isCodeCorrect = props.isCodeCorrect || false;
    const isMobile = props.isMobile;
    const isValueValid = typeof value === 'string' && value.trim() !== '';
    const isEmpty =  (typeof value === 'string' && value.trim() === '');
    return (
        <div className={`ot_inputPromoButton ${props.className ? props.className : ''}`}>
            <TextInput
                aria-label={props["data-test-input"]}
                data-test={props["data-test-input"]}
                className="input_code_filed"
                onFocus={props.onFocus}
                onKeyUp={props.onChange}
                onChange={props.onChange}
                placeholder={props.placeholder}
                disabled={isCodeCorrect}
                value={value}
                isCodeCorrect={props.isCodeCorrect}
                isEmpty = {isEmpty}
                isValueValid = {isValueValid}
            />
            <ApplyCodeButton
                aria-label={props["data-test-button"]}
                buttonType="primary"
                data-test={props["data-test-button"]}
                onClick={props.onClick}
                disabled={!isValueValid}
                isCodeCorrect={isCodeCorrect}
                onRemoveCode={props.onRemoveCode}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            />
            <p
                aria-label={`Error ${props.error}`}
                className={`ot_ci ${isCodeCorrect ? "ot_ci_correct" : (isMobile ? "ot_ci_error_mobile" : "ot_ci_error")}`}
                data-test="error"
            >
                {props.error}
            </p>
        </div>
    );
};
