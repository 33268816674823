import React from "react";
//import "sass/components/seatingChart/Seat.scss";
import { MouseDragHandler } from "components/MouseDragHandler";
import { SEAT_RADIUS, SEAT_STROKE, SEAT_SIZE } from "components/seatingChart/shared/SeatingChartConfig";

export class EditorSeat extends React.Component {
    constructor(props) {
        super(props);
        let dragHandler=new MouseDragHandler({preventDefault:false,stopPropagation:false}).onClick(this.select.bind(this));
        this.state={selected:props.selected || false,dragHandler};
    }
    selectForEditing() {
        this.setState({selected:true},()=>{
            if(typeof this.props.onEditSelect === "function") {
                this.props.onEditSelect(this.props.data,"SEAT",this);
            }
        });
    }
    cancelClick(evt) {
        if(!this.props.disableSeatSelect) {
            evt.stopPropagation();
            evt.preventDefault();
        }
    }
    select(state,hdlr,evt) {
        if(this.props.disableSeatSelect) {
            return;
        }
        this.selectForEditing();
        evt.stopPropagation();
        evt.preventDefault();
        return;
    }

    render() {
        let { data:{available, priceLevel,number, inCart},
          disableClick, isKillSeat, showSeatNumber, textRotation=0 } = this.props;
        return  (
          <g
            onClick={this.cancelClick.bind(this)}
            className={`ot_seat  ot_editorSeat`}
            {...this.state.dragHandler.mouseEvents} >
              <circle
                className="bg"
                cx={SEAT_RADIUS}
                fill="#ccc"
                cy={SEAT_RADIUS}
                r={SEAT_RADIUS-SEAT_STROKE}
                strokeWidth={0}
                />
          </g> );
    }
}
