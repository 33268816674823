export const Storefront = {
  Donations: {
    amount: "storefront.donations.amount",
    amountSelectPlaceholder: "storefront.donations.amountSelectPlaceholder",
    checkoutBtn: "storefront.donations.checkoutBtn",
    companyLocation: "storefront.donations.companyLocation",
    companyMatch: "storefront.donations.companyMatch",
    companyName: "storefront.donations.companyName",
    donationNames: "storefront.donations.donationNames",
    donationNameNote: "storefront.donations.donationNameNote",
    donateAnonymously: "storefront.donations.donateAnonymously",
    endDatePlaceholder: "storefront.donations.endDatePlaceholder",
    endRecurring: "storefront.donations.endRecurring",
    errorMessage: "storefront.donations.errorMessage",
    noCampaign: "storefront.donations.noCampaign",
    noEndDate: "storefront.donations.noEndDate",
    notesPlaceholder: "storefront.donations.notesPlaceholder",
    once: "storefront.donations.once",
    recurrence: "storefront.donations.recurrence",
    waiveBenefits: "storefront.donations.waiveBenefits",
    remainAnonymous: "storefront.donations.remainAnonymous"
  },
  GiftCards: {
    amount: "storefront.giftcard.amount",
    backTo: "storefront.giftcard.backTo",
    buyBtn: "storefront.products.buyBtn",
    cardGreeting: "storefront.giftcard.cardGreeting",
    delivery: "storefront.giftcard.delivery",
    design: "storefront.giftcard.design",
    details: "storefront.giftcard.details",
    email: "storefront.giftcard.email",
    emailInput: "storefront.giftcard.emailInput",
    from: "storefront.giftcard.from",
    maxCharacters: "storefront.giftcard.maxCharacters",
    message: "storefront.giftcard.message",
    printable: "storefront.giftcard.printable",
    stepOne: "storefront.giftcard.stepOne",
    stepTwo: "storefront.giftcard.stepTwo",
    stepThree: "storefront.giftcard.stepThree",
    to: "storefront.giftcard.to"
  },

	Common: {
		buy: "storefront.common.buy",
		checkout:"storefront.common.checkout",
		nextStep: 	"storefront.common.nextStep"
	},
  Packages: {
    checkoutBtn: "storefront.products.checkoutBtn",
    enableAutoRenewal: "storefront.packages.enableAutoRenewal",
    invalidPackage: "storefront.packages.invalidPackage",
    promoCode: "storefront.packages.promoCode",
    promoCodeError: "storefront.packages.promoCodeError",
    promoCodePrice: "storefront.packages.promoCodePrice",
    PackageHeader: {
      selectPerformanceForEach: "storefront.packages.packageHeader.selectPerformanceForEach",
      selectPerformanceForOne: "storefront.packages.packageHeader.selectPerformanceForOne",
      selectSeatsForTicket: "storefront.packages.packageHeader.selectSeatsForTicket",
      reviewPackage: "storefront.packages.packageHeader.reviewPackage"
    },
    FixedPackageAddContainer: {
      bestAvailable: "storefront.packages.fixedPackageAddContainer.bestAvailable"
    },
    ProductionSlide: {
      section: "storefront.packages.productionSlide.section",
      seats: "storefront.packages.productionSlide.seats",
      choose: "storefront.packages.productionSlide.choose",
      edit: "storefront.packages.productionSlide.edit",
      change: "storefront.packages.productionSlide.change"
    },
    GroupSeatingOptions: {
      bestSeatsSelectedForAll: "storefront.packages.groupSeatingOptions.bestSeatsSelectedForAll",
      getSameBestSeatsForAll: "storefront.packages.groupSeatingOptions.getSameBestSeatsForAll",
      editSameSeatForAll: "storefront.packages.groupSeatingOptions.editSameSeatForAll",
      chooseSameSeatsForAll: "storefront.packages.groupSeatingOptions.chooseSameSeatsForAll",
      choosingDifferentSeats: "storefront.packages.groupSeatingOptions.choosingDifferentSeats",
      chooseDifferentSeatsForEach: "storefront.packages.groupSeatingOptions.chooseDifferentSeatsForEach"
    },
    TicketSeatingOptions: {
      bestSelected: "storefront.packages.ticketSeatingOptions.bestSelected",
      getBestAvailable: "storefront.packages.ticketSeatingOptions.getBestAvailable",
      editSeatsSection: "storefront.packages.ticketSeatingOptions.editSeatsSection",
      chooseSeatsSection: "storefront.packages.ticketSeatingOptions.chooseSeatsSection"
    },
    TicketCard: {
      selectPerformanceAndDateForEach: "storefront.packages.ticketCard.selectPerformanceAndDateForEach",
      selectSeatSectionForEach: "storefront.packages.ticketCard.selectSeatSectionForEach",
      summary: "storefront.packages.ticketCard.summary",
      noCommonSeats: "storefront.packages.ticketCard.noCommonSeats",
      notEnoughtSeats: "storefront.packages.ticketCard.notEnoughtSeats",
      selectPerformance: "storefront.packages.ticketCard.selectPerformance",
      changePerfromance: "storefront.packages.ticketCard.changePerformance",
      selectDateTime: "storefront.packages.ticketCard.selectDateTime",
      showLess: "storefront.packages.ticketCard.showLess",
      showMore: "storefront.packages.ticketCard.showMore",
      onlyTicketsAvailable: "storefront.packages.ticketCard.onlyTicketsAvailable"
    },
    GroupCard: {
      hasSameShow: "storefront.packages.groupCard.hasSameShow",
      hasSameSeating: "storefront.packages.groupCard.hasSameSeating"
    },
    SingleHeader: {
      selectSeatingForProduction: "storefront.packages.singleHeader.selectSeatingForProduction"
    },
    GroupHeader: {
      selectSeatsForGroup: "storefront.packages.groupHeader.selectSeatsForGroup"
    },
    ModalHeader: {
      ticketsSelected: "storefront.packages.modalHeader.ticketsSelected"
    },
    TicketDetails: {
      date: "storefront.packages.ticketDetails.date",
      time: "storefront.packages.ticketDetails.time",
      section: "storefront.packages.ticketDetails.section",
      seat: "storefront.packages.ticketDetails.seat"
    },
    PackageStepButton: {
      proceedToNextStep: "storefront.packages.packageStepButton.proceedToNextStep"
    }
  }

}

export const Checkout = {
    Item: {
      less: "checkout.item.less",
      more: "checkout.item.more"
    },
    Cart: {
        total:"checkout.cart.total",
        subTotal:"checkout.cart.subTotal",
        salesTax:"checkout.cart.salesTax",
        viewDetails:"checkout.cart.viewDetails",
        hideDetails:"checkout.cart.hideDetails",
        confirmDelete: "checkout.cart.confirmDelete",
        clearCart: "checkout.cart.clearCart",
        emptyCartMessage: "checkout.cart.emptyCartMessage",
        continueShopping: "checkout.cart.continueShopping",
        convenienceFees: "checkout.cart.convenienceFees",
        Tickets: {
            show:"checkout.cart.tickets.show",
            date:"checkout.cart.tickets.date",
            time:"checkout.cart.tickets.time",
            price: "checkout.cart.tickets.price",
            priceMobile: "checkout.cart.tickets.priceMobile",
            priceLevelName: "checkout.cart.tickets.priceLevelName",
            quantity:"checkout.cart.tickets.quantity",
            quantityMobile: "checkout.cart.tickets.quantityMobile",
            total:"checkout.cart.tickets.total",
            title:"checkout.cart.tickets.title",
            type: "checkout.cart.tickets.type",
            seat: "checkout.cart.tickets.seat",
            section: "checkout.cart.tickets.section",
            sectionMobile: "checkout.cart.tickets.sectionMobile",
            includesFacilityFee:"checkout.cart.tickets.includesFacilityFee"
        },
        GiftCards: {
            to:"checkout.cart.giftCards.to",
            from:"checkout.cart.giftCards.from",
            amount:"checkout.cart.giftCards.amount",
            giftCard:"checkout.cart.giftCards.giftCard",
            item:"checkout.cart.giftCards.item",
            giftCardFor:"checkout.cart.giftCards.giftCardFor",
            deliveryType:"checkout.cart.giftCards.deliveryType",
            title:"checkout.cart.giftCards.title",
            selectAmount:"checkout.cart.giftCards.selectAmount",
            otherAmount:"checkout.cart.giftCards.otherAmount",
            editGiftCard:"checkout.cart.giftCards.editGiftCard",
            deleteGiftCard:"checkout.cart.giftCards.deleteGiftCard",
            addGiftCard:"checkout.cart.giftCards.add",
            validAmountError:"checkout.cart.giftCards.validAmountError",
            minimumAmountError:"checkout.cart.giftCards.minimumAmountError"
        },
        Donations: {
            title:"checkout.cart.donations.title",
            payment:"checkout.cart.donations.payment",
            campaign:"checkout.cart.donations.campaign",
            recurrence:"checkout.cart.donations.recurrence",
            donationDate: "checkout.cart.donations.donationDate",
            nextDonation:"checkout.cart.donations.nextDonation",
            amount:"checkout.cart.donations.amount",
            noEndDate: "checkout.cart.donations.noEndDate",
            validAmountError:"checkout.cart.donations.validAmountError",
        },
        Products: {
            name:"checkout.cart.products.name",
            price:"checkout.cart.products.price",
            quantity:"checkout.cart.products.quantity",
            total:"checkout.cart.products.total",
            title:"checkout.cart.products.title"
        },
        Packages: {
            name:"checkout.cart.packages.name",
            pricePoint:"checkout.cart.packages.pricePoint",
            amount:"checkout.cart.packages.amount",
            title:"checkout.cart.packages.title",
            callout:"checkout.cart.packages.callout",
            invalidCode:"checkout.cart.packages.invalidCode",
            packageRenewal:"checkout.cart.packages.packageRenewal"
        },
        TicketName: {
            title:"checkout.cart.ticketName.title",
            addName: "checkout.cart.ticketName.addName",
            changeName: "checkout.cart.ticketName.changeName",
            mobileLabel: "checkout.cart.ticketName.mobileLabel",
            label:"checkout.cart.ticketName.label",
            validationError: "checkout.cart.ticketName.validationError"
        },
        DeliveryMethod: {
            title: "checkout.cart.deliveryMethod.title",
            autoSelectedDeliveryMethodTitle: "checkout.cart.autoSelected.deliveryMethod.title",
            defaultOption: "checkout.cart.deliveryMethod.defaultOption",
            label: "checkout.cart.deliveryMethod.label",
            mobileButtonLabel: "checkout.cart.deliveryMethod.mobileButtonLabel",
            notSelectedError: "checkout.cart.deliveryMethod.notSelectedError",
            free:"checkout.cart.deliveryMethod.free"
        },
        Guests: {
            addGuests:"checkout.cart.guests.addGuests",
            editGuests:"checkout.cart.guests.editGuests",
            label:"checkout.cart.guests.label",
            guestNumber:"checkout.cart.guests.guestNumber",
            addAnotherGuest: "checkout.cart.guests.addAnotherLink",
            saveGuestsLabel: "checkout.cart.guests.saveGuestsLabel",
            validationError:"checkout.cart.guests.validationError",
            saveGuest:"checkout.cart.guests.saveGuest",
            updateGuest:"checkout.cart.guests.updateGuest",
            deleteGuest:"checkout.cart.guests.deleteGuest",
            prevGuest:"checkout.cart.guests.prevGuest",
            nextGuest:"checkout.cart.guests.nextGuest",
        }
    },
    ProductUpsell: {
        addProduct:"checkout.productUpsell.addProduct",
        upsellTitle: "checkout.productUpsell.upsellTitle",
        limitReached: "checkout.productUpsell.limitReached",
        showPrices: "checkout.productUpsell.showPrices",
        showDescription: "checkout.productUpsell.showDescription",
    },
    Upsell: {
        defaultOption:"checkout.upsell.defaultOption",
        label:"checkout.upsell.label",
        learnMoreAbout:"checkout.upsell.learnMoreAbout",
        amount:"checkout.upsell.amount",
        otherAmount:"checkout.upsell.otherAmount",
        selectLevel:"checkout.upsell.selectLevel",
        oneTime:"checkout.upsell.oneTime",
        recurring:"checkout.upsell.recurring",
        monthly:"checkout.upsell.monthly",
        quarterly:"checkout.upsell.quarterly",
        yearly:"checkout.upsell.yearly",
        endDonations: "checkout.upsell.endDonations",
        donationCount: "checkout.upsell.donationCount",
        addDonation: "checkout.upsell.addDonation",
        noEndDate: "checkout.upsell.noEndDate",
        otherAmountPlaceholder: "checkout.upsell.otherAmountPlaceholder",
        minimumErrorMessage: "checkout.upsell.minimumErrorMessage",
        numberRequiredErrorMessage: "checkout.upsell.numberRequiredErrorMessage",
        selectEndDate: "checkout.upsell.selectEndDate",
        howOften: "checkout.upsell.howOften",
        greaterThanZero: "checkout.upsell.greaterThanZero",
        thankyou: "checkout.upsell.thankyou",
        addedtocart: "checkout.upsell.addedtocart"
    },
    Navigation: {
        cartStep:"checkout.nav.cartStep",
        contactDetails:"checkout.nav.contactDetails",
        paymentInfo:"checkout.nav.paymentInfo",
        reviewOrder:"checkout.nav.reviewOrder"
    },
    ContactDetails: {
        title: "checkout.contactDetails.title",
        mailingAddrDifferent:"checkout.contactDetails.mailingAddrDifferent",
        formError:"checkout.contactDetails.formError",
        switchUser:"checkout.contactDetails.switchUser",
        haveAccount:"checkout.contactDetails.haveAccount",
        editContactInformation: "checkout.contactDetails.editContactInformation",
        makeDefault: "checkout.contactDetails.makeDefault",
        updateContactInformation: "checkout.contactDetails.updateContactInformation",
        checkingOutAs: "checkout.contactDetails.checkingOutAs",
        mandatoryFields: "checkout.contactDetails.mandatoryFields"
    },
    Questions: {
        title: "checkout.questions.title"
    },
    PaymentInfo: {
        title: "checkout.paymentInfo.title",
        noPaymentNeeded: "checkout.paymentInfo.noPaymentNeeded"
    },
    TicketProtection:{
      title:"checkout.ticketProtection.title",
        xtitle: "checkout.ticketProtection.xtitle",
        summary1:"checkout.ticketProtection.summary1",
        summary2:"checkout.ticketProtection.summary2",
        summary:"checkout.ticketProtection.xsummary",
      reason1:"checkout.ticketProtection.reason1",
      reason2:"checkout.ticketProtection.reason2",
      reason3:"checkout.ticketProtection.reason3",
        xreason1:"checkout.ticketProtection.xreason1",
        xreason2:"checkout.ticketProtection.xreason2",
        xreason3:"checkout.ticketProtection.xreason3",
      selectionText:"checkout.ticketProtection.selectionText",
      terms:"checkout.ticketProtection.terms",
      accept:"checkout.ticketProtection.accept",
      deny:"checkout.ticketProtection.deny",
      reviewOrder:"checkout.ticketProtection.reviewOrder",
        modified1:"checkout.ticketProtection.modified1",
        modified2:"checkout.ticketProtection.modified2",
        viewWording:"checkout.ticketProtection.xviewWording",
        insurance:"checkout.ticketProtection.xinsurance",
        poweredBy:"checkout.ticketProtection.xpowered",
        numberOfReasons:"checkout.ticketProtection.numberOfReasons",
        xdisabledWarning: "checkout.ticketProtection.xdisabledWarning",
        xskippedWarning: "checkout.ticketProtection.xskippedWarning",
        xaccept: "checkout.ticketProtection.xaccept",
        xdeny: "checkout.ticketProtection.xdeny"
    },
    ReviewOrder: {
        title: "checkout.reviewOrder.title",
        addressHeader: "checkout.reviewOrder.addressHeader",
        paymentInfoHeader: "checkout.reviewOrder.paymentInfoHeader",
        additionalCharges: "checkout.reviewOrder.additionalCharges",
        toc:"checkout.reviewOrder.toc",
        tocAgree:"checkout.reviewOrder.tocAgree",
        defaultSignup: "checkout.reviewOrder.defaultSignup",
        optIn:"checkout.reviewOrder.tmMail",
        privacyLink:"checkout.reviewOrder.privacyLink",
        errors:"checkout.reviewOrder.errors",
        completeOrder:"checkout.reviewOrder.completeOrder",
        wosPrivacyPolicyTitle:"checkout.reviewOrder.wosPrivacyPolicyTitle",
        privacyPolicyTitle:"checkout.reviewOrder.privacyPolicyTitle",
        tmEmailOptinTitle:"checkout.reviewOrder.tmEmailOptinTitle",
        privacyPolicy:"checkout.reviewOrder.privacyPolicy",
        refundPolicy:"checkout.reviewOrder.refundPolicy",
        fundraisingPolicy:"checkout.reviewOrder.fundraisingPolicy",
        nameCaption:"checkout.reviewOrder.refundPolicy",
        guestCaption:"checkout.reviewOrder.guestCaption",
        guestButtonLabel:"checkout.reviewOrder.guestButtonLabel",
        completeOrderButton:"checkout.reviewOrder.completeOrderButton",
        "acceptTosError": "checkout.reviewOrder.acceptTosError",
        paidWithMasterPass:"checkout.reviewOrder.paidWithMasterPass"
    },
    OrderComplete: {
        orderConfirmed:"checkout.orderComplete.orderConfirmed",
        orderInfo:"checkout.orderComplete.orderInfo",
        importantInfo:"checkout.orderComplete.importantInfo",
        additionalNotes:"checkout.orderComplete.additionalInfo",
        boHours:"checkout.orderComplete.boHours",
        boPhone:"checkout.orderComplete.boPhone",
        venueInfo:"checkout.orderComplete.venueInfo",
        printTicket:"checkout.orderComplete.printTicket",
        receipt: "checkout.orderComplete.receipt",
        viewReceipt: "checkout.orderComplete.viewReceipt",
        hideReceipt: "checkout.orderComplete.hideReceipt",
        eTickets: "checkout.orderComplete.eTickets",
        shippingAddress: "checkout.orderComplete.shippingAddress",
        payment: "checkout.orderComplete.payment",
        chargeInfo: "checkout.orderComplete.chargeInfo",
        total: "checkout.orderComplete.total",
        printGiftCard:"checkout.orderComplete.printGiftCard",
        Tickets: {
            orderNumber: "checkout.orderComplete.ticket.orderNumber",
            section: "checkout.orderComplete.ticket.section",
            seat: "checkout.orderComplete.ticket.seat",
            type: "checkout.orderComplete.ticket.type",
            price: "checkout.orderComplete.ticket.price"
        },
        TicketProtection: {
            xFailureTitle:"checkout.orderComplete.ticketProtection.xFailureTitle",
            xFailureReason:"checkout.orderComplete.ticketProtection.xFailureReason",
            xFailureBody:"checkout.orderComplete.ticketProtection.xFailureBody"
        }
    },
    Common: {
        title:"checkout.common.title",
        address:"checkout.common.address",
        zipcode:"checkout.common.zipcode",
        city:"checkout.common.city",
        state:"checkout.common.state",
        phone:"checkout.common.phone",
        firstName: "checkout.common.firstName",
        lastName: "checkout.common.lastName",
        email:"checkout.common.email",
        enterResponse: "checkout.common.enterResponse",
        ccName: "checkout.common.ccName",
        ccNumber: "checkout.common.ccNumber",
        cvv: "checkout.common.cvv",
        cvvInfo: "checkout.common.cvvInfo",
        expDate: "checkout.common.expDate",
        orderProcessing:"checkout.common.orderProcessing"
    }
};

export const SeatingChartKeys = {
  chooseTicketTypes: "seatingChart.chooseTicketTypes",
  chooseSeats: "seatingChart.chooseSeats",
  nextStep: "seatingChart.nextStep",
  seatLabel: "seatingChart.seatLabel",
  seatTypeHandicap: "seatingChart.seatTypeHandicap",
  seatTypeCompanion: "seatingChart.seatTypeCompanion",
  legend: "seatingChart.legend",
  convenienceFeeLabel: "seatingChart.convenienceFeeLabel",
  promoCodeRemoval: {
    promoModalTitle: "seatingChart.promoCodeRemoval.promoModalTitle",
    promoModalContent: "seatingChart.promoCodeRemoval.promoModalContent",
    promoModalBtnText: "seatingChart.promoCodeRemoval.promoModalBtnText"
  },
  accessibilityModal: {
    accModalTitle: "seatingChart.accessibilityModal.accModalTitle", 
    accModalContent: "seatingChart.accessibilityModal.accModalContent",
    accModalBtnText: "seatingChart.accessibilityModal.accModalBtnText",
    accModalCheckboxText: "seatingChart.accessibilityModal.accModalCheckboxText",
  },
  SeatSelection: {
    priceRangeFilterLabel: "seatingChart.seatSelection.priceRangeFilter.Label",
    mapFilterMenuMinimumLabel: "seatingChart.seatSelection.MapFilterMenu.minimumLabel",
    mapFilterMenuMaximumLabel: "seatingChart.seatSelection.MapFilterMenu.maximumLabel",
    mapFilterMenuResetBtnLabel: "seatingChart.seatSelection.MapFilterMenu.resetBtnLabel",
    mapFilterMenuApplyBtnLabel: "seatingChart.seatSelection.MapFilterMenu.applyBtnLabel",
    mapToolbarBackBtnLabel: "seatingChart.maptoolbar.backBtnLabel",
    accessibilitySeatFilterLabel: "seatingChart.seatSelection.accessibilitySeatFilterLabel",
    accessibilitySeatFilterMenuResetBtnLabel: "seatingChart.seatSelection.accessibilitySeatFilterMenuResetBtnLabel",
    accessibilitySeatFilterMenuApplyBtnLabel: "seatingChart.seatSelection.accessibilitySeatFilterMenuApplyBtnLabel",
    accessibilitySeatFilterMenuDescription : "seatingChart.seatSelection.accessibilitySeatFilterMenuDescription"
  },
  MembershipMessage: {
    notificationTitle: "seatingChart.membershipMessage.notificationTitle",
    notificationText: "seatingChart.membershipMessage.notificationText",
  }
}

export const Errors = {
  Cart: {
    timeout: "errors.cart.timeout",
    aboutToExpireTitle:"errors.cart.aboutToExpireTitle",
    aboutToExpireText:"errors.cart.aboutToExpireText",
    Expired: "errors.cart.Expired",
  }
}

export const FormErrors = {
  Client: {
    unexpected: "errors.client.unexpected",
    noNetwork: "errors.client.noNetwork",
    notFound: "errors.client.notFound",
    dialogTitle: "errors.client.dialogTitle"
  }
}

export const ProductionCalendar = {
    days: {
      sunday: "productionCalendar.days.sunday",
      monday: "productionCalendar.days.monday",
      tuesday: "productionCalendar.days.tuesday",
      wednesday: "productionCalendar.days.wednesday",
      thursday: "productionCalendar.days.thursday",
      friday: "productionCalendar.days.friday",
      saturday: "productionCalendar.days.saturday",
    },
    shutOffMessage: "productionCalendar.shutOffMessage",
    noInventoryMessage: "productionCalendar.noInventoryMessage",
    packageBenefitsDisplayed:"productionCalendar.packageBenefitsDisplayed",
    ticketsHaveBeenAddedMessage:"productionCalendar.ticketsHaveBeenAddedMessage",
    usePromoCode:"productionCalendar.usePromoCode",
    enterPromoCode:"productionCalendar.enterPromoCode",
    promoCode:"productionCalendar.promoCode",
    promotions:"productionCalendar.promotions",
    regularPrice:"productionCalendar.regularPrice",
    ticketsHeader:"productionCalendar.ticketsHeader",
    totalHeader:"productionCalendar.totalHeader",
    dateHeader:"productionCalendar.dateHeader",
    dateSelectHeader:"productionCalendar.dateSelectHeader",
    ticketSelect:"productionCalendar.ticketSelect",
    readDetails:"productionCalendar.backToDetails",
    addToCart:"productionCalendar.addToCart",
    checkout:"productionCalendar.checkout",
    browseForOtherShows:"productionCalendar.browseForOtherShows",
    continueShopping:"productionCalendar.continueShopping",
    buyTickets:"productionCalendar.buyTickets",
    removePromo:"productionCalendar.removePromo",
    usePromo:"productionCalendar.usePromo",
    applyPromo:"productionCalendar.applyPromo",
    noPerformanceAvailable:"productionCalendar.noPerformanceAvailable",
    performanceNotAvailable:"productionCalendar.performanceNotAvailable",
    seeEvent: "productionCalendar.seeEvent",
    dayNotAvailable: "productionCalendar.dayNotAvailable",
    daySoldOut: "productionCalendar.daySoldOut",
    dayCancelled: "productionCalendar.dayCancelled",
    dayToday: "productionCalendar.dayToday",
    deleteSeatSelection: "productionCalendar.deleteSeatSelection",
    productionNotFound: "productionCalendar.productionNotFound",
    buyPackagesButton:"productionCalendar.buyPackagesButton",
    buyPackagesTitle:"productionCalendar.buyPackagesTitle",
    needLoginButton:"productionCalendar.needLoginButton",
    "packageHolderOnly": "productionCalendar.packageHolderOnly",
    "packageHolderOnlyLongMessage": "productionCalendar.packageHolderOnlyLongMessage",
    "packageHolderOnly2": "productionCalendar.packageHolderOnly2",
    "regularShowtimes": "productionCalendar.regularShowtimes",
    "noWebSales": "productionCalendar.noWebSales",
    "needNecessaryPackage":"productionCalendar.needNecessaryPackage",
    loginPackageBenefits: "productionCalendar.loginPackageBenefits",
    ticketsReedemed:"productionCalendar.ticketsRedeemed",
    ticketsSoldOut:"productionCalendar.ticketsSoldOut",
    "showHeader":"productionCalendar.showHeader",
    "timeHeader":"productionCalendar.timeHeader",
    noSeatingChart: "productionCalendar.noSeatingChart",
    seatPrompt: "productionCalendar.seatPrompt",
    chooseSystem: "productionCalendar.chooseSystem",
    chooseUser: "productionCalendar.chooseUser",
    noSeatsForSale: "productionCalendar.noSeatsForSale",
    packageEligible: "productionCalendar.packageEligible",
    packageEligible2: "productionCalendar.packageEligible2",
    selectShowtime: "productionCalendar.selectShowtime",
    next: 'productionCalendar.next',
    findBestAvailableSeating: "productionCalendar.findBestAvailableSeating",
    selectSeatsFromMap: "productionCalendar.selectSeatsFromMap",
    includingFees:"productionCalendar.includingFees",
    freeAdmission:"productionCalendar.freeAdmission",
    MobileTicketType: {
      include: "productionCalendar.mobileTicketType.include",
      allTicketsRedeemed: "productionCalendar.mobileTicketType.allTicketsRedeemed",
      soldOut: 'productionCalendar.mobileTicketType.soldOut'
    }
};
export const PatronCredits = {
    RedeemCredit:{
        loginPrompt:"patronCredits.redeemCredit.loginPrompt",
        balanceDue:"patronCredits.redeemCredit.balanceDue",
        creditsTitle:"patronCredits.redeemCredit.creditsTitle",
        giftCardsTitle:"patronCredits.redeemCredit.giftCardsTitle",
        patronCreditsTitle:"patronCredits.redeemCredit.patronCreditsTitle",
        registerGiftCard:"patronCredits.redeemCredit.registerGiftCard",
        notEligibleToRedeem: "patronCredits.redeemCredit.notEligibleToRedeem"
    },
    RegisterGiftCard:{
        modalTitle: "patronCredits.registerGiftCard.modalTitle",
        registerPlaceholder:"patronCredits.registerGiftCard.registerPlaceholder",
        submitButton:"patronCredits.registerGiftCard.submitButton"
    }
};
export const Common = {
    details:"common.details",
    edit: "common.edit",
    moreLink:"common.moreLink",
    soldOut:"common.soldOut",
    successfullyAdded:"common.successfullyAdded",
    addedToCart:"common.addedToCart",
    regular:"common.regular",
    Options: {
        other: "common.options.other",
        blank: "common.options.blank",
        optional: "common.options.optional",
        recommended: "common.options.recommended"
    },
    Navigation: {
        packages:"common.nav.packages",
        calendar:"common.nav.calendar",
        donations:"common.nav.donations",
        giftCards:"common.nav.giftCards",
        products:"common.nav.products",
        shoppingCart:"common.nav.shoppingCart",
        login:"common.nav.login",
        shop:"common.nav.shop",
        viewCart:"common.nav.viewCart",
        checkout:"common.nav.checkout",
        menu:"common.nav.menu"
    },
    Buttons: {
        yes:"common.buttons.yes",
        continue: "common.buttons.continue",
        noThanks: "common.buttons.noThanks",
        submit:"common.buttons.submit",
        close: "common.buttons.close",
        cancel: "common.buttons.cancel",
        delete: "common.buttons.delete",
        learnMore: "common.buttons.learnMore",
        swipe:  "common.buttons.swipe",
        update:"common.buttons.update",
        ok:"common.buttons.ok",
        back:"common.buttons.back",
        done:"common.buttons.done",
        next:"common.buttons.next"
    },
    Media: {
        landscapeMessage: "common.media.landscapeMessage"
    }
};
export const Login = {
    logoutConfirmationText: "login.logoutConfirmationText",
    logoutConfirmation:"login.logoutConfirmation",
    logoutConfirmationSeatingChart: "login.logoutConfirmationSeatingChart",
    logoutConfirmationYes: "login.logoutConfirmationYes",
    logoutConfirmationNo: "login.logoutConfirmationNo",
    loginLink:"login.loginLink",
    logoutLink:"login.logoutLink",
    myaccount:"login.myaccount",
    title:"login.title",
    emailLabel:"login.emailLabel",
    passwordLabel:"login.passwordLabel",
    useCustomLoginLabel:"login.useCustomLoginLabel",
    userNormalLoginLabel:"login.userNormalLoginLabel",
    customLoginLabel:"login.customLoginLabel",
    loginButtonText:"login.loginButtonText",
    forgotPassword:"login.forgotPassword",
    forgotPasswordError:"login.forgotPasswordError",
    forgotCustomLogin:"login.forgotCustomLogin",
    consumerMismatchError: "login.consumerMismatchError",
    consumerMismatchErrorAction: "login.consumerMismatchErrorAction" ,
    loginSuccessMessage: "login.loginSuccessMessage",
    logoutSuccessMessage: "login.logoutSuccessMessage",
    manageAccountLink: "login.manageAccountLink",
    loginWith: "login.loginWith",
    continueAs: "login.continueAs",
    continueAsGuest: "login.continueAsGuest",
    continueAsGuestWith: "login.continueAsGuestWith",
    Register: {
        createAccountLabel: "login.register.createAccountLabel",
        buttonText:"login.register.buttonText",
        registerLabel:"login.register.registerLabel",
        createAccount:"login.register.createAccount",
        title:"login.register.title",
        retypePassword:"login.register.retypePassword",
        password:"login.register.password",
        backLink:"login.register.backLink",
        addButtonText:"login.register.addButtonText",
        passwordMatchMsg:"login.register.passwordMatchMsg",
        successMsg: "login.register.successMsg",
        loginToYourAccount: "login.register.loginToYourAccount",
    },
    ForgotPassword: {
        emailSent:"login.forgotPassword.emailSent",
        resendEmail:"login.forgotPassword.resendEmail",
        buttonText: "login.forgotPassword.buttonText",
        mainText: "login.forgotPassword.mainText",
        customLoginText:"login.forgotPassword.customLoginText",
        emailNotFound:"login.forgotPassword.emailNotFound"
    }
};

/*
UPDATE `s6_trs`.`i18n_values` SET `value`='Payment: {0}!!' WHERE `i18n_id`='2542';

INSERT INTO `s6_trs`.`i18n_values` (`system`, `package`, `i18n_key`, `value`) VALUES ('CI', 'Common', 'common.buttons.done', 'Done!!');

INSERT INTO `s6_trs`.`i18n_values` (`system`, `package`, `i18n_key`, `value`) VALUES ('CI', 'Common', 'common.media.landscapeMessage', 'Rotate your phone to Portrait!!');

*/
