import React from 'react';
import {FormElement} from 'components/form/FormElement';
import {SvgTextInput} from 'components/form/elements/SvgTextInput';
import {TextInput} from 'components/form/elements/TextInput';



/**
 * A label element that upon double click, allows the label to be changed. The change functionality may be disabled
 * via the disableEditing prop
 */
export class EditableLabel extends FormElement{
  clickCount = 0
  
  singleClickTimer = null

  getDefaults = (props) => {
    const value = (typeof props.value !== 'undefined' && props.value !== null) ?
      props.value : ((typeof props.defaultValue !== 'undefined' && props.defaultValue !== null) ?
        props.defaultValue : "");
        
    return {
      value,
      tmpValue: value
    };
  }

  state = {
    ...this.getDefaults(this.props),
    editing: this.props.editing || false,
    focused: this.props.editing || false,
  }

  componentWillReceiveProps(props) {
    if (typeof props.value !== 'undefined' && props.value !== null) {
      this.setState({
        value: "" + props.value
      });
    }
  }

  /**
   * onDoubleClick - enable text editing mode
   */
  onDoubleClick = () => {
    if (!this.props.disableEditing) {
      this.setState({
        editing: true,
        focused: true
      });
      if (typeof this.props.onDoubleClick === "function") {
        this.props.onDoubleClick();
      }
    }
  }

  get value() {
    return this.state.value
  }

  /**
   * onChange - controlled component makes this necessary.
   */
  onChange = (evt) => {
    if (typeof this.props.value === "undefined") {
      this.setState({
        value: evt.target.value
      });
    }
    if (typeof this.props.onChange === "function") {
      this.props.onChange(evt.target.value, this, evt);
    }
  }

  /**
   * onBlur - handles the blur event of the text input. If a blur event is provided, it forwards it. It then commits the change to the label,
   * removes editing mode, and raises the onChangeCommit event.
   */
  onBlur = (evt) => {
    const { value } = this.state;
    this.setState({
      focused: false,
      editing: false
    }, () => {
      if (typeof this.props.onBlur === "function") {
        this.props.onBlur(evt, this);
      }
      if (typeof this.props.onChangeCommit === "function") {
        this.props.onChangeCommit(value, evt, this);
      }
    });
  }
  textBlur() {

  }
  render() {
    const { ...props } = this.props;
    const { editing } = this.state;
    return (
      <label onDoubleClick={this.onDoubleClick} {...props} >
        {editing ? (
          <TextInput
            style={{ height: "20px" }}
            value={this.state.value}
            onBlur={this.onBlur}
            onChange={(evt, tmpValue) => this.setState({ tmpValue })}
          />
        ) : (
          <label>{this.state.value}</label>
        )}
      </label>
    );
  }
}

/**
 * The svg editable label is an editable label that can be used in an <svg> tag
 */
export class SvgEditableLabel extends EditableLabel {
  textInputArea = React.createRef()

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.editing && this.state.editing) {
      document.addEventListener("mousedown", this.onMouseDown);
    }
    else if (prevState.editing && !this.state.editing) {
      document.removeEventListener("mousedown", this.onMouseDown);
    }
  }

  onClick = (e) => {
    this.clickCount++;
    if (this.clickCount === 1) {
      this.singleClickTimer = setTimeout(() => {
        this.clickCount = 0;
        if (this.props.onClick) {
          this.props.onClick(e);
        }
      }, 500);
    }
    else if (this.clickCount === 2) {
      clearTimeout(this.singleClickTimer);
      this.clickCount = 0;
      this.onDoubleClick(e);
    }
  }

  onChange = (value) => {
    this.setState({ value },() => {
      if (typeof this.props.onChange === "function") {
        this.props.onChange(value);
      }
    });
  }

  onEnter = (e) => this.onBlur(e)

  onMouseDown = (e) => {
    if (this.state.focused && this.state.editing && this.textInputArea && this.textInputArea.current) {
			if (textInputArea.current && !this.textInputArea.current.contains(e.target)) {
				this.onEnter(e);
			}
    }
  }

  renderForLabel() {
    const { style, data, ...props } = this.props;
    // style = Object.assign({},style,{ dominantBaseline:"text-before-edge" });
    // onClick={this.onClick.bind(this)}
    return (
      <g onClick={this.onClick}>
        <text
          {...props}
          id={data ? data.id : ''}
          style={style}
          dy="1em"
          className="ot_textObject chart_object"
        >
          {this.state.value}
        </text>
      </g>
    );
  }
  renderForEditing() {
    const style = Object.assign({}, this.props.style, { opacity:1 });

    return (
      <SvgTextInput
        ref={this.textInputArea}
        {...this.props}
        className="ot_svgText chart_object"
        style={style}
        value={this.state.value}
        hasFocus={true}
        onEnter={this.onEnter}
        onChange={this.onChange}
      />
    );
  }
  render() {
    return this.state.editing ?
        this.renderForEditing() : this.renderForLabel();
  }
}
