import { updatedDiff } from 'deep-object-diff'
import { createSelector } from 'reselect'
import { VectorUtils, GraphicsUtils } from 'util/MathFunctions'
import { EditorSection } from 'components/seatingChart/editor/EditorSection'
import { Section } from 'components/seatingChart/seatSelection/Section'
import { SeatingChartObject } from 'components/seatingChart/shared/objects/SeatingChartObject'
import { ViewPort } from 'components/ViewPort'
import { ChartConst } from 'util/Constants'

import {
	VIEW_PORT_SIZE,
	PADDING,
	SEAT_SIZE,
	SEAT_SPACING,
	SEAT_SPACING_Y,
	UnitConversion,
} from 'components/seatingChart/shared/SeatingChartConfig'

const { MIN_ZOOM_SCALE, MAX_ZOOM_SCALE } = ChartConst

const getHasOwnProperty = (obj, key) => {
	return Object.prototype.hasOwnProperty.call(obj, key)
}

/**
 *
 */
export const getSeatingChartReducer = (state, props) =>
	state.seatingChartReducer

/**
 *
 */
export const getIsSeatingChartProcessing = (state, props) =>
	state.seatingChartReducer.seatingCharts.getIsProcessing()

/**
 *
 */
export const getSeatingChart = (state, props) =>
	state.seatingChartReducer.seatingCharts.getSeatingChartById(
		props.params.seatingChartId
	)

/**
 *
 */
export const getSeatingChartSelectedObject = (state, props) =>
	state.seatingChartReducer.seatingCharts.getSelectedObject()

/**
 *
 */
export const getSeatingChartNewObjects = (state, props) =>
	state.seatingChartReducer.seatingCharts.getNewObjects()

/**
 *
 */
export const getSeatingChartUpdatedObjects = (state, props) =>
	state.seatingChartReducer.seatingCharts.getUpdatedObjects()

/**
 *
 */
export const getSeatingChartRemovedObjects = (state, props) =>
	state.seatingChartReducer.seatingCharts.getRemovedObjects()

/**
 *
 */
export const getSeatingChartTool = (state, props) =>
	state.seatingChartReducer.seatingCharts.getTool()

/**
 *
 */
export const getSeatingChartAnchor = (state, props) =>
	state.seatingChartReducer.seatingCharts.getAnchor()

/**
 *
 */
export const getSeatingChartViewPort = (state, props) =>
	state.seatingChartReducer.seatingCharts.getViewPort()

/**
 *
 */
export const getSeatingChartViewScale = (state, props) =>
	state.seatingChartReducer.seatingCharts.getViewScale()

/**
 *
 */
export const getSeatingChartViewPan = (state, props) =>
	state.seatingChartReducer.seatingCharts.getViewPan()

/**
 *
 */
export const getSeatingChartUndoSectionHistory = (state, props) =>
	state.seatingChartReducer.seatingCharts.getUndoSectionHistory()

/**
 *
 */
export const getSeatingChartRedoSectionHistory = (state, props) =>
	state.seatingChartReducer.seatingCharts.getRedoSectionHistory()

/**
 *
 */
export const getClientSeatingChartViewPan = (state, props) =>
	state.seatingChartReducer.seatingCharts.getClientViewPan()

/**
 *
 */
export const getTemporarySelectedSeat = (state, props) =>
	state.seatingChartReducer.seatingCharts.getTemporarySelectedSeat()

/**
 *
 */
export const getSelectedSeats = (state, props) =>
	state.seatingChartReducer.seatingCharts.getSelectedSeats()

/**
 *
 */
export const getSeatingChartUpdatedNewObjects = createSelector(
	[
		getSeatingChart,
		getSeatingChartSelectedObject,
		getSeatingChartNewObjects,
		getSeatingChartUpdatedObjects,
	],
	({ seatingChart }, selectedObject, newObjects, updatedObjects) =>
		newObjects.reduce((updatedNewObjects, newObject) => {
			if (newObject.type !== 'SECTION') {
				if (getHasOwnProperty(updatedObjects['OBJECT'], newObject.id)) {
					updatedNewObjects.push(updatedObjects['OBJECT'][newObject.id])
				} else {
					updatedNewObjects.push(newObject)
				}
			}
			return updatedNewObjects
		}, [])
)

/**
 *
 */
export const getSeatingChartUpdatedNewSections = createSelector(
	[
		getSeatingChart,
		getSeatingChartSelectedObject,
		getSeatingChartNewObjects,
		getSeatingChartUpdatedObjects,
	],
	({ seatingChart }, selectedObject, newObjects, updatedObjects) =>
		newObjects.reduce((updatedNewObjects, newObject) => {
			if (newObject.type === 'SECTION') {
				if (getHasOwnProperty(updatedObjects[newObject.type], newObject.id)) {
					updatedNewObjects.push(updatedObjects[newObject.type][newObject.id])
				} else {
					updatedNewObjects.push(newObject)
				}
			}
			return updatedNewObjects
		}, [])
)

/**
 *
 */
export const getSeatingChartObjects = createSelector(
	[
		getSeatingChart,
		getSeatingChartSelectedObject,
		getSeatingChartUpdatedObjects,
		getSeatingChartUpdatedNewObjects,
		getSeatingChartRemovedObjects,
	],
	(
		{ seatingChart },
		selectedObject,
		updatedObjects,
		newObjects,
		removedObjects
	) =>
		seatingChart.objects
			.map((object) =>
				selectedObject &&
				selectedObject.type !== 'SECTION' &&
				selectedObject.id === object.id
					? selectedObject
					: updatedObjects &&
							updatedObjects['OBJECT'] &&
							getHasOwnProperty(updatedObjects['OBJECT'], object.id)
						? updatedObjects['OBJECT'][object.id]
						: object
			)
			.concat(newObjects)
			.filter((object) => !getHasOwnProperty(removedObjects, object.id))
)

/**
 *
 */
export const getSeatingChartSections = createSelector(
	[
		getSeatingChart,
		getSeatingChartSelectedObject,
		getSeatingChartUpdatedObjects,
	],
	({ seatingChart }, selectedObject, updatedObjects) => {
		return seatingChart.sections.map((section) =>
			selectedObject &&
			selectedObject.type === 'SECTION' &&
			selectedObject.id === section.id
				? selectedObject
				: updatedObjects &&
						updatedObjects['SECTION'] &&
						getHasOwnProperty(updatedObjects['SECTION'], section.id)
					? updatedObjects['SECTION'][section.id]
					: section
		)
	}
)

/**
 *
 */
export const getAllOriginalObjectsAndSections = (state, props) => {
	const { seatingChart } = getSeatingChart(state, props)

	const all = seatingChart.sections.concat(seatingChart.objects)

	return all.reduce(
		(originals, item) => {
			if (item.type === 'SECTION') {
				originals['SECTION'][item.id] = item
			} else {
				originals['OBJECT'][item.id] = item
			}
			return originals
		},
		{
			SECTION: {},
			OBJECT: {},
		}
	)
}

/**
 *
 */
export const getClientAllOriginalObjectsAndSections = (state, props) => {
	const { data: seatingChart } = props

	const all = seatingChart.sections.concat(seatingChart.objects)

	return all.reduce(
		(originals, item) => {
			if (item.type === 'SECTION') {
				originals['SECTION'][item.id] = item
			} else {
				originals['OBJECT'][item.id] = item
			}
			return originals
		},
		{
			SECTION: {},
			OBJECT: {},
		}
	)
}

/**
 *
 */
export const getObjectPositions = createSelector(
	[getSeatingChartObjects],
	(objects) => {
		const reducer = (positions, object) => {
			positions[object.id] = {
				posX: object.posX,
				posY: object.posY,
			}
			return positions
		}

		return objects ? objects.reduce(reducer, {}) : {}
	}
)

/**
 *
 */
export const getSectionPositions = createSelector(
	[getSeatingChartSections, getSeatingChartUpdatedObjects],
	(sections, updatedObjects) => {
		const reducer = (positions, section) => {
			positions[section.id] = {
				posX: section.posX,
				posY: section.posY,
			}
			return positions
		}

		const sectionPositions = sections ? sections.reduce(reducer, {}) : {}

		return updatedObjects && updatedObjects['SECTION']
			? Object.values(updatedObjects['SECTION']).reduce(
					reducer,
					sectionPositions
				)
			: sectionPositions
	}
)

/**
 *
 */
export const getSectionPosition = createSelector(
	[getSectionPositions, (state, ownProps) => ownProps.item],
	(sectionPositions, section) =>
		getHasOwnProperty(sectionPositions, section.id)
			? {
					x: sectionPositions[section.id].posX * (SEAT_SIZE + SEAT_SPACING),
					y: sectionPositions[section.id].posY * (SEAT_SIZE + SEAT_SPACING_Y),
				}
			: {
					x: section.posX * (SEAT_SIZE + SEAT_SPACING),
					y: section.posY * (SEAT_SIZE + SEAT_SPACING_Y),
				}
)

/**
 *
 */
export const isSectionSelected = createSelector(
	[getSeatingChartSelectedObject, (state, ownProps) => ownProps.item.id],
	(selectedObject, sectionId) =>
		selectedObject &&
		selectedObject.type === 'SECTION' &&
		selectedObject.id === sectionId
)

/**
 *
 */
export const getDisplayableSections = createSelector(
	[getSectionPositions],
	(positions) =>
		Object.keys(positions).reduce((displayable, sectionId) => {
			if (
				!getHasOwnProperty(displayable, sectionId) &&
				(positions[sectionId].posX !== 0 || positions[sectionId].posY !== 0)
			) {
				displayable[sectionId] = true
			}
			return displayable
		}, {})
)

/**
 *
 */
export const getAvailableSections = createSelector(
	[getSectionPositions],
	(positions) =>
		Object.keys(positions).reduce((available, sectionId) => {
			if (
				!getHasOwnProperty(available, sectionId) &&
				(positions[sectionId].posX === 0 || positions[sectionId].posY === 0)
			) {
				available[sectionId] = true
			}
			return available
		}, {})
)

/**
 *
 */
export const getSelectedSectionUndoHistory = createSelector(
	[getSeatingChartSelectedObject, getSeatingChartUndoSectionHistory],
	(selectedObject, sectionHistory) =>
		selectedObject &&
		selectedObject.type === 'SECTION' &&
		sectionHistory &&
		getHasOwnProperty(sectionHistory, selectedObject.id)
			? sectionHistory[selectedObject.id]
			: []
)

/**
 *
 */
export const getSelectedSectionRedoHistory = createSelector(
	[getSeatingChartSelectedObject, getSeatingChartRedoSectionHistory],
	(selectedObject, sectionHistory) =>
		selectedObject &&
		selectedObject.type === 'SECTION' &&
		sectionHistory &&
		getHasOwnProperty(sectionHistory, selectedObject.id)
			? sectionHistory[selectedObject.id]
			: []
)

/**
 * returns all objects, sections
 */
export const getAllItems = createSelector(
	[getSeatingChartObjects, getSeatingChartSections],
	(objects, sections) => sections.concat(objects)
)

/**
 * returns all objects, sections that were updated
 */
export const getAllItemsUpdated = createSelector(
	[getAllOriginalObjectsAndSections, getAllItems],
	(originalItems, allItems) =>
		allItems.filter((item) => {
			const oType = item.type === 'SECTION' ? 'SECTION' : 'OBJECT'

			// new item?
			if (!getHasOwnProperty(originalItems[oType], item.id)) {
				return true
			}

			const diff = updatedDiff(originalItems[oType][item.id], item)
			return Object.keys(diff).length > 0
		})
)

/**
 *
 */
export const getAllItemsSorted = createSelector(
	[getAllItems, getSeatingChartSelectedObject],
	(items, selectedObject) =>
		items.sort((obj1, obj2) => {
			if (selectedObject && selectedObject.id === obj1.id) {
				return selectedObject.layer - obj2.layer
			} else if (selectedObject && selectedObject.id === obj2.id) {
				return obj1.layer - selectedObject.layer
			}
			return obj1.layer - obj2.layer
		})
)

/**
 *
 */
export const getMinMaxPoints = createSelector(
	[
		getSeatingChartSections,
		getSectionPositions,
		getSeatingChartObjects,
		getObjectPositions,
	],
	(sections, sectionPositions, objects, objectPositions) => {
		const combinePoints = (ret, section) => {
			const rect = EditorSection.getAbsoluteBoundingRect(
				section,
				sectionPositions
			)
			return ret.concat(GraphicsUtils.polygonFromRectangle(rect))
		}

		const combineObjectPoints = (ret, object) => {
			const rect = SeatingChartObject.getAbsoluteBoundingRect(
				object,
				objectPositions
			)
			return ret.concat(GraphicsUtils.polygonFromRectangle(rect))
		}

		// get points from all bounding rectangles
		const objectPoints = objects ? objects.reduce(combineObjectPoints, []) : []
		const sectionPoints = sections
			? sections.reduce(combinePoints, objectPoints)
			: []

		const allPoints = []
			.concat(objectPoints)
			.concat(sectionPoints)
			.filter((vec) => !isNaN(vec[0]) && !isNaN(vec[1]))

		const maxPoint = VectorUtils.max(...allPoints) || [0, 0] // find max point of all bounding rectangles
		const minPoint = VectorUtils.min(...allPoints) || [0, 0]
		const centerPoint = VectorUtils.midpoint(minPoint, maxPoint) || [0, 0]

		return {
			maxPoint,
			minPoint,
			centerPoint,
		}
	}
)

/**
 *
 */
export const getClientMinMaxPoints = createSelector(
	[getClientAllOriginalObjectsAndSections],
	(allObjectsAndSections) => {
		const combineSectionPoints = (ret, section) =>
			ret.concat(
				GraphicsUtils.polygonFromRectangle(
					Section.getAbsoluteBoundingRect(section)
				)
			)

		const combineObjectPoints = (ret, object) =>
			ret.concat(
				GraphicsUtils.polygonFromRectangle(
					SeatingChartObject.getAbsoluteBoundingRect(object)
				)
			)

		const allPoints = Object.values(allObjectsAndSections['OBJECT'])
			.reduce(
				combineObjectPoints,
				Object.values(allObjectsAndSections['SECTION']).reduce(
					combineSectionPoints,
					[]
				)
			)
			.filter((vec) => !isNaN(vec[0]) && !isNaN(vec[1]))

		//get points from all bounding rectangles
		const maxPoint = VectorUtils.max(...allPoints) || [0, 0]
		const minPoint = VectorUtils.min(...allPoints) || [0, 0]
		const centerPoint = VectorUtils.midpoint(minPoint, maxPoint) || [0, 0]

		return {
			maxPoint,
			minPoint,
			centerPoint,
		}
	}
)

/**
 *
 */
export const getSeatingChartSize = createSelector(
	[getMinMaxPoints],
	({ maxPoint, minPoint }) => VectorUtils.subtract(maxPoint, minPoint)
)

/**
 *
 */
export const getClientSeatingChartSize = createSelector(
	[getClientMinMaxPoints],
	({ maxPoint, minPoint }) => VectorUtils.subtract(maxPoint, minPoint)
)

/**
 *
 */
export const getHomeCenter = createSelector(
	[getMinMaxPoints],
	({ minPoint, maxPoint }) => {
		const topLeft = [
			UnitConversion.xPixelsToUnits(minPoint[0]),
			UnitConversion.yPixelsToUnits(minPoint[1]),
		]
		const bottomRight = [
			UnitConversion.xPixelsToUnits(maxPoint[0]),
			UnitConversion.yPixelsToUnits(maxPoint[1]),
		]
		const center = [
			topLeft[0] + (bottomRight[0] - topLeft[0]) / 2,
			topLeft[1] + (bottomRight[1] - topLeft[1]) / 2,
		]

		return center
	}
)

/**
 *
 */
export const createViewPortFromData = createSelector(
	[getMinMaxPoints, getSeatingChartSize, getSeatingChartViewPort],
	({ minPoint, maxPoint, centerPoint }, chartSize, viewPort) => {
		if (viewPort) {
			return {
				viewPort,
			}
		}

		const newViewPort = new ViewPort(
			VIEW_PORT_SIZE,
			chartSize,
			minPoint,
			PADDING
		)
		const scale = newViewPort.scaleOfFit
		newViewPort.scaleArea(scale)
		return {
			viewPort: newViewPort,
			scale: {
				current: scale,
				home: scale,
				max: scale > MAX_ZOOM_SCALE ? scale : MAX_ZOOM_SCALE,
				min: scale < MIN_ZOOM_SCALE ? scale : MIN_ZOOM_SCALE,
			},
		}
	}
)

/**
 *
 */
export const getClientViewPortSize = (state, props) => props.viewPortSize

/**
 *
 */
export const getClientViewPortChartCenter = (state, props) =>
	state.seatingChartReducer.seatingCharts.getViewPortChartCenter()

/**
 *
 */
export const createClientViewPortFromData = createSelector(
	[
		getClientMinMaxPoints,
		getClientSeatingChartSize,
		getSeatingChartViewPort,
		getClientViewPortSize,
		getClientViewPortChartCenter,
	],
	(
		{ maxPoint, minPoint, centerPoint },
		chartSize,
		viewPort,
		viewPortSize,
		viewPortChartCenter
	) => {
		if (viewPort) {
			return {
				viewPort,
				viewPortChartCenter,
				chart: {
					minPoint,
					maxPoint,
					centerPoint,
				},
				chartSize,
			}
		}

		const newViewPort = new ViewPort(viewPortSize, chartSize)
		const scale = newViewPort.scaleOfFit
		newViewPort.centerToScale(scale, centerPoint)

		return {
			viewPort: newViewPort,
			viewPortChartCenter: centerPoint,
			chartSize,
			chart: {
				minPoint,
				maxPoint,
				centerPoint,
			},
			scale: {
				min: scale < MIN_ZOOM_SCALE ? scale : MIN_ZOOM_SCALE,
				max: scale > MAX_ZOOM_SCALE ? scale : MAX_ZOOM_SCALE,
				home: scale,
				current: scale,
			},
		}
	}
)

/**
 *
 */
export const createViewportAttributes = createSelector(
	[getMinMaxPoints, createViewPortFromData, getHomeCenter],
	({ minPoint, maxPoint, centerPoint }, viewData, center) => {
		return {
			...viewData,
			minPoint,
			maxPoint,
			centerPoint,
			center,
			pan: {
				dX: 0,
				dY: 0,
			},
		}
	}
)

/**
 *
 */
export const createClientViewportAttributes = createSelector(
	[createClientViewPortFromData],
	(viewData) => viewData
)

/**
 *
 */
export const getViewportAttributes = createSelector(
	[createViewportAttributes, getSeatingChartViewPan, getSeatingChartViewScale],
	(view, pan, scale) => {
		if (scale.current === null) {
			return view
		}

		// view.viewPort.move(pan.dX, pan.dY);
		return {
			...view,
			pan,
			scale,
		}
	}
)

/**
 *
 */
export const getClientViewportAttributes = createSelector(
	[
		createClientViewportAttributes,
		getSeatingChartViewScale,
		getClientSeatingChartViewPan,
	],
	(view, scale, pan) => {
		if (scale.current === null) {
			return view
		}

		// view.viewPort.move(pan.dX, pan.dY);
		return {
			...view,
			pan,
			scale,
		}
	}
)

/**
 * check that the seating chart has displayable sections.
 *
 * @return boolean
 */
export const getHasSectionLayout = createSelector(
	[getDisplayableSections],
	(displayableSections) => Object.keys(displayableSections).length > 0
)

/**
 *
 */
export const getHasChanges = (state, props) => {
	const originals = getAllOriginalObjectsAndSections(state, props)
	const updatedObjects = getSeatingChartUpdatedObjects(state, props)
	const removedObjects = getSeatingChartRemovedObjects(state, props)
	const newObjects = getSeatingChartNewObjects(state, props)

	return (
		Object.keys(updatedDiff(originals['SECTION'], updatedObjects['SECTION']))
			.length > 0 ||
		Object.keys(updatedDiff(originals['OBJECT'], updatedObjects['OBJECT']))
			.length > 0 ||
		newObjects.length > 0 ||
		Object.keys(removedObjects).length > 0
	)
}
