import { VectorUtils } from '../util/MathFunctions'

import { ChartConst } from 'util/Constants'
let { MAX_ZOOM_SCALE, MIN_ZOOM_SCALE } = ChartConst

let _areaSize = Symbol('areaSize')
let _areaSizeUnscaled = Symbol('areaSizeUnscaled')
let _center = Symbol('center')
let _location = Symbol('location')
let _offset = Symbol('offset')
let _offsetUnscaled = Symbol('offsetUnscaled')
let _maxOffset = Symbol('maxOffset')
let _minOffset = Symbol('minOffset')
let _padding = Symbol('padding')
let _viewPortSize = Symbol('viewPortSize')

export class ViewPort {
	constructor(
		viewPortSize,
		size,
		offset = [0, 0],
		padding = { top: 0, right: 0, bottom: 0, left: 0 }
	) {
		if (!size) {
			size = viewPortSize
		} else if (!Array.isArray(size)) {
			size = VectorUtils.scalarMultiply(viewPortSize, size)
		}

		this[_areaSize] = size
		this[_areaSizeUnscaled] = size
		this[_center] = [viewPortSize[0] / 2, viewPortSize[1] / 2]
		this[_location] = [0, 0]
		this[_padding] = padding
		this[_viewPortSize] = viewPortSize
		this[_offset] = offset
		this[_offsetUnscaled] = offset
		this[_maxOffset] = this.getViewPortArea()
		this[_minOffset] = VectorUtils.scalarMultiply(this[_maxOffset], -1)
	}

	getAreaCenter(location = this[_location]) {
		console.log('GETAREACENTER')
		let [posX, posY] = VectorUtils.add(this[_offset], location)
		let [width, height] = this[_areaSize]

		return [posX + width / 2, posY + height / 2]
	}

	getInitialCenter() {
		let viewPortArea = this.getViewPortArea()
		let { top, left } = this[_padding]
		return [viewPortArea[0] / 2 + left, viewPortArea[1] / 2 + top]
	}

	getHomeLocation(scale) {
		// console.log('GETHOMELOCATION ***');
		let scaledLocation = VectorUtils.scalarMultiply(this[_location], scale)
		let areaCenter = this.getAreaCenter(scaledLocation)

		this[_center] = this.getInitialCenter()

		return VectorUtils.subtract(this[_center], areaCenter)
	}

	getCenteredLocation(scale, center) {
		let [posX, posY] = center
		let [width, height] = this[_areaSize]
		let [offX, offY] = this[_offset]

		return [posX - width / 2 - offX, posY - height / 2 - offY]
	}

	getTargetedLocation(scale, relativeOffset) {
		let centeredLocation = this.getCenteredLocation(scale, this[_center])
		return VectorUtils.subtract(centeredLocation, relativeOffset)
	}

	getViewPortArea() {
		let [width, height] = this[_viewPortSize]
		let { top, left, bottom, right } = this[_padding]

		return [width - left - right, height - top - bottom]
	}

	boundOffset(newlocation) {
		let [posX, posY] = newlocation

		let minChart = { x: this.areaSize[0] / 4, y: this.areaSize[1] / 4 }
		let min = { x: minChart.x * -4, y: minChart.y * -4 }
		let max = {
			x: this.viewPortSize[0] + this.areaSize[0],
			y: this.viewPortSize[1] + this.areaSize[1],
		}
		if (posX < min.x) posX = min.x
		else if (posX > max.x) posX = max.x

		if (posY < min.y) posY = min.y
		else if (posY > max.y) posY = max.y

		return [posX, posY]
	}

	boundOffsetOnScale(newlocation, scale) {
		let [posX, posY] = newlocation

		const multiplier =
			scale.min > 0
				? MAX_ZOOM_SCALE / scale.min
				: MAX_ZOOM_SCALE / MIN_ZOOM_SCALE
		let min = {
			x: this.viewPortSize[0] * -multiplier,
			y: this.viewPortSize[1] * -multiplier,
		}
		let max = {
			x: this.viewPortSize[0] * multiplier,
			y: this.viewPortSize[1] * multiplier,
		}

		if (posX < min.x) posX = min.x
		else if (posX > max.x) posX = max.x

		if (posY < min.y) posY = min.y
		else if (posY > max.y) posY = max.y

		return [posX, posY]
	}

	reCenter(center) {
		let [x, y] = center
		let newLocation = [x, y]
		this[_location] = this.boundOffset(newLocation)
	}

	reCenterScale(center, scale) {
		let [x, y] = center
		let newLocation = [x, y]
		this[_location] = this.boundOffsetOnScale(newLocation, scale)
	}

	centerOn(center) {
		let [x, y] = center
		let [width, height] = this[_areaSize]
		console.log('old location', this[_location])
		let newLocation = [x - width / 2, y - height / 2]
		this[_location] = this.boundOffset(newLocation)
		console.log('Center got newPosition', this[_location])
	}

	move(dx, dy) {
		let distance = VectorUtils.toVector(dx, dy)
		let newLocation = VectorUtils.add(distance, this[_location])
		this[_location] = this.boundOffset(newLocation)
		console.log('MOVE to newPosition', this[_location])
	}

	/* to calculate boundOffset considering scale */
	pan(dx, dy, scale) {
		let distance = VectorUtils.toVector(dx, dy)
		let newLocation = VectorUtils.add(distance, this[_location])
		this[_location] = this.boundOffsetOnScale(newLocation, scale)
	}

	/**
	 * @deprecated
	 */
	scaleArea(scale, center, relativeOffset) {
		this[_areaSize] = VectorUtils.scalarMultiply(this.areaSizeUnscaled, scale)
		this[_offset] = VectorUtils.scalarMultiply(this[_offsetUnscaled], scale)

		if (relativeOffset) {
			relativeOffset = VectorUtils.scalarMultiply(relativeOffset, scale)
			this[_location] = this.getTargetedLocation(scale, relativeOffset)
		} else if (center) this[_location] = this.getCenteredLocation(scale, center)
		else this[_location] = this.getHomeLocation(scale)
	}

	zoom(newScale, center) {
		if (!center) center = this.getAreaCenter()

		this.scaleArea(newScale, center)
		/*if ( VectorUtils.assertSameVectors(center, this[_center]) ) this.scaleArea(newScale, center );
		else {
			let relativeOffset = VectorUtils.subtract(this[_center], center);
			this.scaleArea(newScale, this[_center], relativeOffset );
		} */
	}

	scaleToCenterEditor(scale, newScale, centerPoint) {
		const scaleDifference = newScale - scale.current
		const centerScaled = VectorUtils.scalarMultiply(
			centerPoint,
			scaleDifference
		)
		const prevLocation = this[_location]
		this[_location] = VectorUtils.subtract(prevLocation, centerScaled)
		return this
	}

	centerToScale(scale, centerPoint) {
		const centerScaled = VectorUtils.scalarMultiply(centerPoint, scale)

		// now we need the vector difference between the current origin (viewPortArea center) and the chartCenter.
		const viewPortCenter = VectorUtils.scalarMultiply(this[_viewPortSize], 0.5)

		this[_location] = [
			centerScaled[0] <= 0
				? centerScaled[0] <= viewPortCenter[0]
					? Math.abs(centerScaled[0])
					: centerScaled[0] * -1
				: centerScaled[0] * -1,

			centerScaled[1] <= 0
				? centerScaled[1] <= viewPortCenter[1]
					? Math.abs(centerScaled[1])
					: centerScaled[1] * -1
				: centerScaled[1] * -1,
		]

		return this
	}

	/* GETTERS */
	get areaSize() {
		return this[_areaSize] && this[_areaSize].slice()
	}

	get areaSizeUnscaled() {
		return this[_areaSizeUnscaled].slice()
	}

	get canMove() {
		return this.areaSize.some((item, index) => item > this.viewPortSize[index])
	}

	get center() {
		return this[_center].slice()
	}

	get location() {
		/* return {number[]}  [x,y] location point of the viewport */
		if (this[_location]) return this[_location].slice()
	}

	get maxOffset() {
		return this[_maxOffset].slice()
	}

	get minOffset() {
		return this[_minOffset].slice()
	}

	get offset() {
		return Object.assign({}, this[_offset])
	}

	get offsetUnscaled() {
		return Object.assign({}, this[_offsetUnscaled])
	}

	get padding() {
		return Object.assign({}, this[_padding])
	}

	get scaleOfFit() {
		return (
			VectorUtils.componentDivide(this[_maxOffset], this[_areaSize]).reduce(
				(acc, item) => Math.min(item, acc),
				Number.MAX_VALUE
			) * 0.75
		)
	}

	get viewPortCenter() {
		return VectorUtils.scalarMultiply(this[_maxOffset], 0.5)
	}

	get viewPortSize() {
		/* return {number[]}  [width,height] size of the viewport */
		if (this[_viewPortSize]) return this[_viewPortSize].slice()
	}
}
