import React from 'react'

import styled from 'styled-components'
import { theme } from '../../../../theme'

const GeneralInfo = styled.div`
	width: 300px;

	display: flex;
`

const InfoBox = styled.div`
	width: ${({ width }) => width};
`

const InfoBoxHeading = styled.p`
	font-weight: ${theme.fontWeight.regular};
	font-size: ${theme.fontSizes.xs};

	margin-bottom: ${theme.margin.xxs};
`

const InfoBoxData = styled.p`
	font-weight: ${theme.fontWeight.bold};
	font-size: ${theme.fontSizes.sm};
`

const SelectSeatGeneralInfo = ({ selectedSeat }) => {
	return (
		<GeneralInfo>
			<InfoBox width='40%'>
				<InfoBoxHeading>Section</InfoBoxHeading>
				<InfoBoxData>{selectedSeat.sectionName}</InfoBoxData>
			</InfoBox>

			<InfoBox width='30%'>
				<InfoBoxHeading>Row</InfoBoxHeading>
				<InfoBoxData>{selectedSeat.row}</InfoBoxData>
			</InfoBox>

			<InfoBox width='30%'>
				<InfoBoxHeading>Seat</InfoBoxHeading>
				<InfoBoxData>{selectedSeat.number}</InfoBoxData>
			</InfoBox>
		</GeneralInfo>
	)
}

export default SelectSeatGeneralInfo
