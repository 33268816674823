import React from "react";
import PropTypes from 'prop-types';

export class ModalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.handleBgdUnlock = this.handleBgdUnlock.bind(this);
    }
    componentWillUnmount() {
        if (this.props.isController) {
            this.handleBgdUnlock();
        }
    }
    handleBgdUnlock() {
        let { posY } = this.props;
        document.getElementById("main").removeAttribute("style");
        document.body.scrollTop  = posY;
        window.scroll(0, posY);
    }
    render() {
        let newStyle = Object.assign({}, this.props.style);
        if (!this.props.transparent){
            newStyle = Object.assign({}, newStyle,
              {zIndex: this.props.zIndex || '20000', backgroundColor: `rgba(${this.props.clear ? "255,255,255" : "0,0,0"}, ${'0.6'})`});
        }
        return (
          <section className={this.props.containerClass} style={{position: 'relative'}}>
            <div className="modalContainer anime_fadeIn"
                style={newStyle}>
                <div className={`modalWrapper ${this.props.className}`} onClick={this.props.onClick}>
                    {this.props.children}
                </div>
            </div>
          </section>
        );
    }
}


ModalContainer.propTypes = {
    clear: PropTypes.bool,
    transparent: PropTypes.bool,
    onClick: PropTypes.func
};
