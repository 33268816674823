import React from "react";
import {RectangleObject} from "./RectangleObject";

/**
 * An object in the shape of an arrow. the points are the same as a rectangle object
 */
export class ArrowObject extends RectangleObject {
  render() {
    const {
      data,
      isContextual
    } = this.props;

    const {
      posX,
      posY,
      width,
      height
    } = this.convertUnits();

    // draws an arrow
    return (
      <g transform={`rotate(${data.rotation} ,${posX + width / 2}, ${posY + height / 2})`}>
        <path
          style={{
            opacity: data.opacity / 100
          }}
          d={`
            M ${posX} ${posY + height / 4}
            h ${3 * width / 4}
            v ${-height / 4}
            l ${width / 4} ${height / 2}
            l ${-width / 4} ${height / 2}
            v ${-height / 4}
            h ${-3 * width / 4}
            Z
          `}
          fill={isContextual ? "#CCC" : data.fill}
          stroke={isContextual ? "#CCC" : data.stroke}
        />
        {/*this.props.editing && this.renderEditingNubs()*/}
      </g>
    );
  }
}
