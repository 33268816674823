import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE_URL = process.env.REACT_APP_WEBSOCKET_SERVICE_URL;

export const getAllPromos = async (performanceId) => {
    const token = Cookies.get('token');
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    };
    try {
        const response = await axios.get(`${API_BASE_URL}/qsp-plus/v1/promotions/${performanceId}/promotions`, { headers });
        return response.data;
    } catch (error) {
        console.error('Error getAllPromos :', error);
        return false;
    }
};
