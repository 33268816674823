import React from "react";
import PropTypes from "prop-types";
import Flex from "components/common/containers/Flex";
import { ContextualMap } from "components/seatingChart/seatSelection/ContextualMap";
import { ZoomButtons } from "components/seatingChart/shared/ZoomButtons";
import { OTTooltip } from "components/common/textDisplay/OTTooltip";
import "sass/components/seatingChart/seatSelection/mapControllers.scss";

import { ChartConst } from "util/Constants";
let { ZOOM_MENU_HEIGHT } = ChartConst;

export class MapControllers extends React.PureComponent {
  zoomTooltip = React.createRef();

  handleTooltip = (e) => {
    let tooltip = this.zoomTooltip.current;
    tooltip.handleMouseOver(e);
  };

  render() {
    let {
      quantity,
      scale,
      sections,
      zoom,
      pan,
      mobileScale,
      noToolBar,
      isFixedPackageSeating,
      ...props
    } = this.props;
    let { isSmallDevice } = false;
    let style = {
      height: `${ZOOM_MENU_HEIGHT}px`,
    };
    let mobileWidgetHeaderHeight = 0;
    if (document.getElementById("mobileWrap")) {
      mobileWidgetHeaderHeight =
        document.getElementById("mobileWrap").offsetHeight;
    }
    if (isSmallDevice)
      style.bottom =
        mobileWidgetHeaderHeight === 0
          ? isFixedPackageSeating
            ? 70
            : 120
          : mobileWidgetHeaderHeight;
    else style.bottom = 0;
    let sectionsHaveOutlines =
      !!sections && sections.find((section) => section.outline !== null);
    return (
      <Flex className="ot_mapControl" right top style={style}>
				<ContextualMap
					chartSize={this.props.chartSize}
					dragState={this.props.dragState}
					dragging={this.props.dragging}
					objects={this.props.objects}
					viewPortSize={this.props.viewPortSize}
					viewPortChartCenter={this.props.viewPortChartCenter}
					scale={scale}
					pan={pan}
					seatingChartVP={this.props.seatingChart}
					viewPort={this.props.viewPort}
					sections={this.props.sections}
					zoomAction={this.props.zoomAction}
					zoomDepth={this.props.zoomDepth}
					zoomHome={this.props.zoomHome}
					{...props}
				/>
        {/* {!isSmallDevice && sectionsHaveOutlines && !noToolBar && (
          <ContextualMap
            chartSize={this.props.chartSize}
            dragState={this.props.dragState}
            dragging={this.props.dragging}
            objects={this.props.objects}
            viewPortSize={this.props.viewPortSize}
            viewPortChartCenter={this.props.viewPortChartCenter}
            scale={scale}
            pan={pan}
            seatingChartVP={this.props.seatingChart}
            viewPort={this.props.viewPort}
            sections={this.props.sections}
            zoomAction={this.props.zoomAction}
            zoomDepth={this.props.zoomDepth}
            zoomHome={this.props.zoomHome}
            {...props}
          />
        )} */}
        <ZoomButtons
          {...zoom}
          hasPan={this.props.hasPan}
          className="ot_ci_zoomToolbar"
          onMouseOver={this.handleTooltip}
          scale={scale}
          mobileScale={mobileScale}
          isSmallDevice={isSmallDevice}
        />
        <OTTooltip
          ref={this.zoomTooltip}
          align="center"
          position="left"
          offsetX={10}
        />
      </Flex>
    );
  }
}

MapControllers.contextTypes = {
  media: PropTypes.object,
};
